import { Directive, HostListener, Input } from '@angular/core';
import { ContextMenuService } from '../components/context-menu/context-menu.service';
import { ContextMenuOption } from 'src/app/models/context-menu-option.model';

@Directive({
  selector: '[appContextMenu]',
})
export class ContextMenuDirective {
  @Input('appContextMenu') options: ContextMenuOption[] = [];
  @Input() data!: any;

  constructor(private contextMenuService: ContextMenuService) {}

  @HostListener('contextmenu', ['$event']) // Listen to right-click event
  onRightClick(event: MouseEvent) {
    this.contextMenuService.openMenu(event, this.options, this.data);
  }
}
