import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MessageDialogComponent } from '../sms-message-dialog/message-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-schedule-message-dialog',
  templateUrl: './schedule-message-dialog.component.html',
  styleUrls: ['./schedule-message-dialog.component.scss']
})
export class ScheduleMessageDialogComponent implements OnInit {
  form: FormGroup;
  timezoneConfig = {
    hideSelected: false,
    dropdownPosition: 'top',
    appearance: 'underline',
    clearOnBackspace: true,
    closeOnSelect: true,
    appendTo: null,
  };
  hours: string[] = this.generateHours();

  constructor(private fb: FormBuilder,private dialogRef: MatDialogRef<MessageDialogComponent>) { }

  ngOnInit(): void { 
  
    this.form = this.fb.group({
      selectedDate: [null, Validators.required],
      selectedHour: [null, Validators.required],
      timezone: [null, Validators.required]
    });
  }
  

  private generateHours(): string[] {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`;
        times.push(time);
      }
    }
    return times;
  }

  save() : void {
    const { selectedDate, selectedHour, timezone } = this.form.value;
    const dateOnly = moment.utc(selectedDate).format("YYYY-MM-DD");
    const [hours, minutes] = selectedHour.split(':').map((v: string) => parseInt(v, 10));
    const combinedDate = moment.tz(dateOnly,timezone.nameValue).hours(hours)
    .minutes(minutes)
    .seconds(0)
    .milliseconds(0);
    const utc= combinedDate.utc();
    const formattedDate= utc.format('MM/DD/YY hh:mm A z');
    this.dialogRef.close({
      sendTime: formattedDate,
      timezone:timezone
    });
  }

  close():void{
    this.dialogRef.close();
  }

}
