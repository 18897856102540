import { Diagnosis } from 'src/app/models/fhir-models';
import { User } from './user.model';

export interface Patient extends User {
  client_ids?: string[];
  active_rpm?: boolean;
  addresses?: PatientAddress[];
  alert_contacts?: AlertContact[];
  contact_consent?: ContactConsent;
  contacts?: PatientContact[];
  coverages?: InsuranceCoverage[];
  compliance?:Compliance;
  diagnoses?: Diagnosis[];
  language?: string;
  dob?: any;
  emr_id?: string;
  emr_data?: PatientEmrData;
  height_inches?: number;
  last_alert_date?: any;
  last_assessment?: any;
  last_measurement_date?: any;
  lastCall?: LastCall;
  next_follow_up?: string;
  notes?: string;
  pregnancies?: number;
  provider_id?: string;
  secondary_providers?: SecondaryProvider[];
  status?: PatientStatus;
  weight_lbs?: number;
  primary_coverage_type?: CoverageTypes;
  providerGenerated?: boolean;
  lastSurveyDate?: any;
  last_awv_form?: LastAwvForm;
  id?: string;
  billingCodes?: string[];
  primaryContact?: PatientContact;
  viewers_on_chart?: ViewOnChart[];
  hubspot_id?: string;
  programs?:string[];
}

export interface BMIPatient{
  bmi:number;
  category:string
}

export interface Compliance {
    vitalsReport?:VitalsReport;
    timeReportByPatient?:TimeReportByPatient;
}

export interface VitalsReport {
    uniqueMeasures?: number;
    totalMeasures?: number;
}

export interface TimeReportByPatient {
  
    user_id:string;
    rpmc: number,
    rpms:  number,
    rpm:  number,
    rpmTotal:  number,
    pcmTotal:  number,
    pinTotal:  number,
    chiTotal:  number,
    bhiTotal:  number,
    tcm:boolean,
    ccm:  number,
    pcm:  number,
    pin:  number,
    chi:  number,
    bhi:  number,
    contacted: boolean,
    ccmTotal: number,
    other: number,
    billable: number,
    times: []

}


export interface LastAwvForm {
  date?: any;
  id?: string;
}
export interface ViewOnChart {
  name: string;
  user_id: string;
  since?: any;
  tabs_open?: number;
  initials?: string;
  tooltip?: string;
}

export enum ProviderRole {
  'MA' = 'Medical Assistant',
  'NP' = 'Nurse Practitioner',
  'RN' = 'Registered Nurse',
  'CC' = 'Care Coordinator',
  'O' = 'Other',
}
export interface SecondaryProvider {
  uid: string;
  name: string;
  email: string;
  isAvailable: boolean;
  rank: number;
  role?: ProviderRole;
  dateAdded?: any;
}

export interface LastCall {
  lastCallDate?: any;
  lastCallStatus?: string;
  lastCallSid?: string;
  lastCallDuration?: number | string;
}

export interface AlertContact {
  type?: string;
  data?: string;
}

export interface ContactConsent {
  email?: boolean;
  phone?: boolean;
  sms?: any;
}

export interface Roles {
  isPatient?: boolean;
  isClient?: boolean;
  isAdmin?: boolean;
  isWelbyClinical?: boolean;
  isPracticeAdmin?: boolean;
  isManagerNurse?: boolean;
  isMedicalAssistant?: boolean;
}

export interface PatientStatus {
  active?: boolean;
  ccm?: boolean;
  ccm_last_update?: any;
  deceased?: boolean;
  rpm?: boolean;
  rpm_category?: string;
  pcm?: boolean;
  tcm?: boolean;
  rtm?: boolean;
  rtm_last_update?: any;
  tcm_last_update?: any;
  pcm_last_update?: any;
  rpm_last_update?: any;
  bhi?: boolean;
  bhi_last_update?: any;
  disenrollment_ccm_comments?: string;
  disenrollment_rpm_comments?: string;
  label?: string;
}

export interface Message {
  id?: string;
  author_id: string;
  patient_id?: string;
  client_id?: string;
  compose_date: Date;
  text: string;
  read?: boolean;
  patient_sent?: boolean;
}

export interface PatientContact {
  id?: string;
  contact_data?: string; // the info (i.e. email, number)
  contact_type?: 'home' | 'cell' | 'email' | 'fax';
  createdAt: any;
  doc?: any;
  icon_name?: string;
  isPrimary?: boolean;
  primary?: boolean;
  isPrimaryForSms?: boolean;
  updatedAt?: any;
}

export interface PatientAddress {
  id?: string;
  address: string;
  address1?: string;
  address2?: string;
  city: string;
  state: string;
  zip: any;
  zip4?: any;
  zip5?: any;
  postalCode?: any;
  primary?: boolean;
  doc?: any;
}

export interface PatientFiles {
  title: string;
  description: string;
  url: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: {
    id: string;
    name: string;
  };
  user_id: string;
}

export interface InsuranceCoverage {
  id?: string;
  effective_date?: Date;
  end_date?: Date;
  logo_image_url?: string;
  member_id?: string;
  payor?: string;
  plan_type?: string;
}

export interface Device {
  id?: string;
  ordered?: boolean;
  delivered?: boolean;
  active?: boolean;
  auth_token?: string;
  device_manufacturer?: string;
  device_model?: string;
  device_id?: string;
  imageURL?: string;
  last_update?: any;
  refresh_token?: string;
  user_id?: number;
  order_notes?: string;
}

export interface PatientAppointment {
  creator_id?: string;
  patient_id?: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  type?: string;
  status?: AppointmentStatus;
}

export interface AppointmentStatus {
  isActive?: boolean;
  isWaiting?: boolean;
}

export interface PatientEmrData {
  mrn: string;
  emr_guid: string;
}

export enum CoverageTypes {
  MEDICARE = 'Medicare',
  MEDICAID = 'Medicaid',
  COMMERCIAL = 'Commercial',
  CASH = 'Cash',
  OTHER = 'Other',
}

export enum Languages {
  ENGLISH = 'English',
  SPANISH = 'Spanish',
  FRENCH = 'French',
  GERMAN = 'German',
  JAPANESE = 'Japanese',
  MANDARIN = 'Mandarin',
  PORTUGUESE = 'Portuguese',
  VIETNAMESE = 'Vietnamese',
  OTHER = 'Other',
}
