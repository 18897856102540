import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { FirestoreService } from './firestore.service';
import { SnackService } from 'src/app/services/snack.service';
import { Roles } from '../models/patient.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  collectionFSName = 'users';
  lastNameFSName = 'lastName';
  rolesFSQuery = 'roles.';
  constructor(private fs: FirestoreService, private firestore: AngularFirestore, private snackService: SnackService) {}

  updateUser(user: User): Promise<void> {
    return this.fs.update(`users/${user.user_id}`, user);
  }

  updateTimezone(Id: string, timezone: string): Promise<void> {
    return this.firestore.collection('users').doc(Id).update({
      timezone: timezone,
    });
  }

  updateUserAiMessage(userId: string, aiMessageValue: boolean) {
    return this.firestore.collection('users').doc(userId).update({
      enableAImessage: aiMessageValue,
    });
  }

  updatePhone(userId: string, phone: string): Promise<void> {
    return this.firestore.collection('users').doc(userId).update({ phone: phone });
  }

  getUserById(id: string) {
    return this.fs.doc(`users/${id}`).get();
  }

  getUsers(pageSize: number, paginationParams?: any, searchParams?: any) {
    return this.firestore
      .collection(this.collectionFSName, (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = searchParams ? this.getQueryFromSearchParams(query, searchParams) : query;
        if (!searchParams?.lastName) {
          query = query.orderBy(this.lastNameFSName);
        }
        query = paginationParams ? this.getQueryFromPaginatorParams(query, paginationParams) : query;
        query = query.limit(pageSize);
        return query;
      })
      .get()
      .pipe(map((results) => results.docs.map((result) => ({ ...result.data(), doc: result }))));
  }

  private getQueryFromSearchParams(
    ref: firebase.firestore.CollectionReference | firebase.firestore.Query,
    searchParams: any
  ): firebase.firestore.CollectionReference | firebase.firestore.Query {
    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    if (searchParams.role) {
      query = query.where(`${this.rolesFSQuery}${searchParams.role}`, '==', true);
    }
    if (searchParams.firstName) {
      query = query.where(`firstName`, '==', searchParams.firstName);
    }
    if (searchParams.lastName) {
      query = query.where(`lastName`, '==', searchParams.lastName);
    }
    if (searchParams.email) {
      query = query.where(`email`, '==', searchParams.email);
    }
    if (searchParams.user_id) {
      query = query.where(`user_id`, '==', searchParams.user_id);
    }
    if (searchParams.searchTerms) {
      query = query.where('search_terms', 'array-contains', searchParams.searchTerms[0]);
    }
    return query;
  }

  private getQueryFromPaginatorParams(
    ref: firebase.firestore.CollectionReference | firebase.firestore.Query,
    paginatorParams: any
  ): firebase.firestore.CollectionReference | firebase.firestore.Query {
    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    if (paginatorParams && paginatorParams.lastResolution && paginatorParams.isNextClicked) {
      query = query.startAfter(paginatorParams.lastResolution);
    }
    if (paginatorParams && paginatorParams.firstResolution && paginatorParams.isPrevClicked) {
      query = query.startAt(paginatorParams.firstResolution);
    }
    if (paginatorParams && paginatorParams.lastResolution && paginatorParams.isPrevClicked) {
      query = query.endAt(paginatorParams.lastResolution);
    }

    return query;
  }

  updateRoles(userId: string, updatedRoles: Roles) : void {
    const userDoc = this.fs.col('users').doc(userId);
    userDoc.update({ roles: updatedRoles })
        .catch((error) =>{
          console.error('Error updating roles in Firebase:', error);
          this.snackService.genericSnackBar('Role successfully deleted', ['success-snackbar'], 5000)
        } );
  }
}
