import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { AiMessagesService } from 'src/app/services/ai/ai-messages.service';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { SmsService } from 'src/app/services/communications/sms.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { PatientService } from 'src/app/services/patient.service';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-number.validator';
import { ScheduleMessageDialogComponent } from '../schedule-message-dialog/schedule-message-dialog.component';
import { PatientCommunicationService } from 'src/app/services/patient-communication/patient-communication.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { PatientContact } from 'src/app/models/patient.model';
import { FirestoreService } from 'src/app/services/firestore.service';

export interface MessageCategory {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('inputFile') inputFile: ElementRef;
  @Input() message = '';
  @Output() smsSent = new EventEmitter<boolean>();
  contentVisible = true;
  form: FormGroup;
  loading = false;
  loadingAIMessage = false;
  noAIerrors = false;
  aiMessage = false;
  unsubcription = new Subject<void>();
  languageModel = { label: 'Translate To', showLabel: true, appearance: 'outline', showOnlyActive: false };
  categories: MessageCategory[] = [
    { value: 'welby', viewValue: 'Secure Message' },
    { value: 'sms', viewValue: 'SMS Message' },
  ];
  attachments = [];
  attachmentsGreater2MBCounter = 0;
  provider: User;
  scheduledMessageData: any = {};
  smsSignature = '';
  patientContacts$: Observable<PatientContact[]>;
  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    private fs: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private smsService: SmsService,
    private aiMessagesService: AiMessagesService,
    private patientService: PatientService,
    private languageService: LanguageService,
    private snackService: SnackService,
    private utilsService: UtilsService,
    private authService: NewAuthService,
    private dialog: MatDialog,
    private communicationService: PatientCommunicationService,
    private fsService: FirestoreService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message && this.form) {
      this.form.get('text').setValue(changes.message.currentValue);
    }
  }

  ngOnInit(): void {
    this.authService.user$.pipe(takeUntil(this.unsubcription)).subscribe((user) => {
      this.provider = {
        ...user,
        twilio_line: Array.isArray(user.twilio_line) ? user.twilio_line : [user.twilio_line],
      };
      this.form.get('twilioNumber').setValue(this.provider?.twilio_line[0]);
      this.smsSignature = user?.smsSignature ? `\n\n${user?.smsSignature}` : '';
      this.form.get('text').setValue(this.smsSignature);
    });
    this.patientContacts$ = this.fsService.colWithIds$<PatientContact[]>(`users/${this.patientService.currentPatientServiceID}/my_contacts`).pipe(
      takeUntil(this.dialogRef.afterClosed()),
      tap((data) => {
        if (data.length > 0) {
          const primaryContactSms = data.find((contact) => contact.isPrimaryForSms === true);
          if (primaryContactSms) {
            this.form.get('contactNumber').setValue(primaryContactSms.contact_data);
          } else {
            this.form.get('contactNumber').setValue(data[0].contact_data);
          }
        }
      })
    );
    this.initializeForm();
    if (this.data.sms === false) {
      this.categories = [{ value: 'welby', viewValue: 'Secure Message' }];
    } else if (this.data.external) {
      this.categories = [{ value: 'sms', viewValue: 'SMS Message' }];
      this.form.get('number').setValidators(Validators.compose([Validators.required, phoneNumberValidator]));
    }

    this.authService.getAiMessageValue(this.authService.user.user_id).subscribe((value) => {
      this.aiMessage = value;
      if (this.data.useAI2GenerateSMS && this.aiMessage) {
        this.getAIMessage();
      } else {
        this.clearAISurveyForm();
      }
    });
  }

  onToggleChange(event: any): void {
    this.aiMessage = event.checked;
    if (this.aiMessage) {
      this.getAIMessage();
    } else {
      this.clearAISurveyForm();
    }
  }

  initializeForm(): void {
    this.form = this.fs.group({
      text: [this.message ? this.message : this.data?.text, Validators.required],
      type: [this.data.type ?? '', Validators.required],
      // eslint-disable-next-line id-blacklist
      number: [this.data.number ?? ''],
      language: [this.patientService?.currentPatientService?.language ?? 'English'],
      attachments: [],
      twilioNumber: [this.provider?.twilio_line[0], Validators.required],
      contactNumber: [''],
    });
  }

  translateText(event: { name: string; active?: boolean }): void {
    this.loading = true;
    this.languageService.translateText(this.form.get('text').value, event.name).subscribe(
      (res) => {
        this.form.get('text').setValue(res.translatedText);
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clearAISurveyForm(): void {
    this.form.get('text').setValue(this.message ? '' : this.smsSignature);
  }

  async sendData(): Promise<void> {
    this.loading = true;
    const form = this.form.getRawValue();
    this.data.text = form.text;
    this.data.twilio_line = form.twilioNumber;
    this.data.contactNumber = form.contactNumber;
    if (form.number) {
      // eslint-disable-next-line id-blacklist
      this.data.number = form.number.split('-').reduce((curr: string, prev: string) => curr + prev);
    }
    this.data.type = form.type;
    if (this.data.external) {
      this.dialogRef.close(this.data);
    } else {
      const smsWasSent = await this.smsService.handleSMSMessage({ ...this.data, attachments: this.attachments, numberFilesExceed2MB: this.attachmentsGreater2MBCounter });
      this.loading = false;
      this.smsSent.emit(smsWasSent);
      this.dialogRef.close();
    }
  }

  scheduleMessage(): void {
    const dialog = this.dialog.open(ScheduleMessageDialogComponent, {
      width: '400px',
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.scheduledMessageData = result;
        const form = this.form.getRawValue();
        this.data.text = form.text;
        this.data.twilio_line = form.twilioNumber;
        this.data.type = form.type;
        this.smsService
          .saveScheduleMessages({ sms: { ...this.data, to: form.contactNumber, attachments: this.attachments, numberFilesExceed2MB: this.attachmentsGreater2MBCounter }, ...this.scheduledMessageData })
          .then((messageId) => {
            this.dialogRef.close();
            const dateInTimezone = moment.utc(result.sendTime, 'MM/DD/YY hh:mm A [UTC]').tz(result.timezone.nameValue);
            const formattedDate = dateInTimezone.format('MM/DD/YY hh:mm A z');
            this.snackService.genericSnackBar(`The message will be sent on the day: ${formattedDate}`, ['success-snackbar'], 6000);
            const communicationRecord = {
              scheduledMessageId: messageId,
              type: this.data.type.toUpperCase(),
              note: { subject: this.data.type.toUpperCase(), message: this.data.text },
              createdAt: new Date(),
              createdBy: { name: `${this.authService.user?.firstName} ${this.authService.user?.lastName}`, userId: this.authService.user?.user_id },
              patient: {
                patientId: this.patientService.currentPatientService?.user_id ?? '',
                name: `${this.patientService.currentPatientService?.firstName ?? 'N/A'} ${this.patientService.currentPatientService?.lastName ?? 'N/A'}`,
              },
              attachments: this.attachments,
              sendTime: result.sendTime,
              status: 'Pending',
              updatedAt: new Date(),
              timezone: result.timezone,
            };
            this.communicationService.saveCommunicationRecordId(communicationRecord, this.patientService?.currentPatientService?.user_id, messageId);
          })
          .catch((error) => {
            console.error('Error saving scheduled send:', error);
            this.snackService.genericSnackBar('Error saving scheduled send', ['warn-snackbar'], 6000);
          });
      }
    });
  }

  getAIMessage(): void {
    this.loadingAIMessage = true;
    this.aiMessagesService
      .getAIMessage(this.patientService.currentPatientService.provider_id, this.patientService.currentPatientService.user_id)
      .pipe(
        switchMap((res) => {
          if (res.error) {
            this.clearAISurveyForm();
            this.data.useAI2GenerateSMS = false;
            this.snackService.genericSnackBar(res.error, ['warn-snackbar'], 6000);
            return of({ error: res.error });
          } else {
            this.noAIerrors = true;
            return this.form.get('language').value ? this.languageService.translateText(res.message, this.form.get('language').value) : of({ translatedText: res.message });
          }
        })
      )
      .subscribe((res) => {
        if (res.translatedText) {
          this.noAIerrors = true;
          this.form.get('text').setValue(res.translatedText + this.smsSignature);
        }
        this.loadingAIMessage = false;
      });
  }

  async onDocumentFileSelected(event): Promise<void> {
    event.preventDefault();
    const files = await Promise.all(
      Array.from(event.target.files).map(async (file: File) => {
        if (file.size > 2000000) {
          this.snackService.genericSnackBar('File size exceed 2MB. We added a link into SMS to download it.', ['warn-snackbar'], 8000);
          this.attachmentsGreater2MBCounter++;
        }
        const fileBase64 = await this.utilsService.getBase64FromFile(file);
        return { fileName: file.name, contentType: file.type, content: fileBase64 };
      })
    );
    this.attachments = files ? [...files, ...this.attachments] : [];
  }

  toggleSize() {
    if (!this.contentVisible) {
      this.maximizeDialog();
    } else {
      this.minimizeDialog();
    }
  }

  minimizeDialog() {
    this.contentVisible = false;
    this.dialogRef.updatePosition({ bottom: '0', right: '0' });
    this.removeBackDrop();
  }

  maximizeDialog() {
    this.contentVisible = true;
    this.dialogRef.updatePosition();
    this.addBackDrop();
  }

  removeBackDrop(): void {
    const backdrop = document.querySelector('.cdk-overlay-backdrop');
    backdrop.classList.add('cdk-overlay-backdrop-hide');
  }

  addBackDrop(): void {
    const backdrop = document.querySelector('.cdk-overlay-backdrop');
    backdrop.classList.remove('cdk-overlay-backdrop-hide');
  }

  ngOnDestroy(): void {
    this.unsubcription.next();
    this.unsubcription.complete();
  }
}
