import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { Client } from 'src/app/models/client.model';
import { AthenaService } from 'src/app/services/emr/athena.service';
import { AthenaProvider } from 'src/app/services/models/athena.model';
import { PatientDetailService } from 'src/app/services/patient-detail.service';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-number.validator';

@Component({
  selector: 'app-careteam-dialog',
  templateUrl: './careteam-dialog.component.html',
  styleUrls: ['./careteam-dialog.component.scss'],
})
export class CareteamDialogComponent implements OnInit, OnDestroy {
  teamMemberForm: FormGroup;
  types = ['PCP', 'Specialist', 'Family Member', 'Home Health / Caregiver', 'Other'];
  contactMethods = ['fax', 'email', 'none'];
  isLinear = true;
  isLoading = false;
  npiError: string;
  showNPIForm = false;
  unsubscribe$ = new Subject();
  isAthenaEMR$: Observable<boolean>;
  isSearching = false;
  athenaProviders: any[] = [];
  currentClient: Client;

  constructor(
    private dialogRef: MatDialogRef<CareteamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private patientService: PatientDetailService,
    private athenaService: AthenaService,
    private snackService: SnackService
  ) {}

  ngOnInit(): void {
    this.isAthenaEMR$ = this.patientService.client$.pipe(
      tap((client) => (this.currentClient = client)),
      map((client) => client.emr_data.emr_name.includes('athena'))
    );
    this.teamMemberForm = this.fb.group({
      comments: [this.data?.teamMember?.comments ?? '', []],
      first_name: [this.data?.teamMember?.first_name ?? '', [Validators.required]],
      last_name: [this.data?.teamMember?.last_name ?? '', [Validators.required]],
      degree: [this.data?.teamMember?.degree ?? '', []],
      organization: [this.data?.teamMember?.organization ?? '', []],
      speciality: [this.data?.teamMember?.speciality ?? '', []],
      type: [this.data?.teamMember?.type ?? '', [Validators.required]],
      phone: [this.data?.teamMember?.phone ? this.getNormalizedPhoneValue(this.data?.teamMember?.phone) : '', [phoneNumberValidator]],
      email: [this.data?.teamMember?.email ?? '', []],
      fax: [this.data?.teamMember?.fax ?? '', [phoneNumberValidator]],
      method: [this.data?.teamMember?.reports.method ?? '', []],
      rpm: [this.data?.teamMember?.reports.rpm ?? false, []],
      ccm: [this.data?.teamMember?.reports.ccm ?? false, []],
      activeBill: [this.data?.teamMember?.bill.active ?? false, []],
      ccmBill: [{ value: this.data?.teamMember?.bill.ccm ?? false, disabled: true }],
      rpmBill: [{ value: this.data?.teamMember?.bill.rpm ?? false, disabled: true }],
      athena_provider_id: [{ value: this.data?.teamMember?.athena_provider_id ?? '', disabled: true }],
    });
    this.onToggleChange();
    this.teamMemberForm.get('activeBill')?.valueChanges.subscribe((value) => {
      this.onToggleChange();
    });
  }

  openNPIForm() {
    this.showNPIForm = true;
    this.teamMemberForm.disable();
  }

  onToggleChange() {
    if (this.teamMemberForm.get('activeBill').value) {
      this.teamMemberForm.get('ccmBill').enable();
      this.teamMemberForm.get('rpmBill').enable();
    } else {
      this.teamMemberForm.get('ccmBill').disable();
      this.teamMemberForm.get('rpmBill').disable();
      this.teamMemberForm.patchValue({ ccmBill: false, rpmBill: false });
    }
  }

  hideNPIForm(show?: boolean): void {
    this.showNPIForm = show ?? false;
    this.teamMemberForm.enable();
  }

  setTeamMemberData(data) {
    console.log(data);
    this.teamMemberForm.patchValue({
      first_name: data.first_name,
      last_name: data.last_name,
      organization: data.organization,
      phone: data.phone,
      fax: data.fax,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  searchAthenaProvider(): void {
    const searchTerm = this.teamMemberForm.get('last_name').value?.trim().replace(/[-_]/g, ' ');
    if (!searchTerm) {
      return;
    }

    this.isSearching = true;
    this.athenaService
      .searchProvidersByName(searchTerm, this.currentClient.client_id)
      .pipe(finalize(() => (this.isSearching = false)))
      .subscribe({
        next: (response) => {
          this.athenaProviders = response.providers || [];
          if (this.athenaProviders.length === 0) {
            this.snackService.genericSnackBar(`No providers found with last name ${searchTerm}`, ['error-snackbar'], 5000);
          }
        },
        error: (error) => {
          console.error('Error searching Athena providers:', error?.message || 'Internal Error');
          this.snackService.genericSnackBar(`${error?.error?.error ?? 'Internal Error'} - ${error?.error?.message ?? 'See logs'}`, ['error-snackbar'], 5000);
          this.athenaProviders = [];
        },
      });
  }

  selectProvider(provider: AthenaProvider): void {
    this.teamMemberForm.get('athena_provider_id').setValue(provider.providerid);
    this.athenaProviders = [];
  }

  save(): void {
    const formData = this.teamMemberForm.getRawValue();
    this.dialogRef.close(formData);
  }

  private getNormalizedPhoneValue(phone: string) {
    const phoneWithoutSign = phone.substring(2).trim();
    return this.utilsService.getPhoneNumberFormated(phoneWithoutSign);
  }
}
