<mat-card class="basicinfo-card">
  <div class="basicinfo-header">
    <h2 class="basicinfo-subtitle"><mat-icon class="basicinfo-icon">assignment_ind</mat-icon> Basic Information</h2>
  </div>
  <form class="basicinfo-form-container" [formGroup]="basicinfoForm">
    <div class="basicinfo-form-row">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput placeholder="First name" formControlName="firstName" />
        <mat-hint>Please fill First Name</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="Last Name" formControlName="lastName" />
        <mat-hint>Please fill Last Name</mat-hint>
      </mat-form-field>
    </div>
    <div class="basicinfo-form-row">
      <mat-form-field appearance="outline">
        <mat-label>Middle Name</mat-label>
        <input matInput placeholder="Middle name" formControlName="middleName" />
        <mat-hint>Please fill Middle Name</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Gender</mat-label>
        <mat-select formControlName="gender">
          <mat-option *ngFor="let gender of genderTypes" [value]="gender">
            {{ gender.charAt(0).toUpperCase() + gender.substring(1).toLowerCase() }}
          </mat-option>
        </mat-select>
        <mat-hint>Please select gender</mat-hint>
      </mat-form-field>
    </div>
    <div class="basicinfo-form-row">
      <mat-form-field>
        <mat-label>Date of birthday</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="dob" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint>Please fill Birthday</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Primary coverage</mat-label>
        <mat-select formControlName="primary_coverage_type">
          <mat-option *ngFor="let coverage of coverages" [value]="coverage">
            {{ coverage }}
          </mat-option>
        </mat-select>
        <mat-hint>Please select primary coverage</mat-hint>
      </mat-form-field>
    </div>
    <div class="basicinfo-form-row">
      <mat-form-field appearance="outline">
        <mat-label>Height(inches)</mat-label>
        <input matInput placeholder="Height" formControlName="height_inches" />
        <mat-hint>Please fill heights</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Weight(lbs)</mat-label>
        <input matInput placeholder="Height" formControlName="weight_lbs" />
        <mat-hint>Please fill weight</mat-hint>
      </mat-form-field>
    </div>
    <div class="basicinfo-form-row">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" formControlName="email" />
        <mat-hint *ngIf="showChangeEmailButton">The 'Change email' button is enabled at the bottom of the form when the user changes the patient's email.</mat-hint>
        <mat-error *ngIf="basicinfoForm.get('email').errors?.email">Please add a correct email. i.e welby&#64;welby.com</mat-error>
      </mat-form-field>
      <app-language-selector [languageInput]="basicinfoForm.get('language')" [languageModel]="languageModel"></app-language-selector>
    </div>
    <div class="basicinfo-form-row" formGroupName="emr_data">
      <mat-form-field appearance="outline">
        <mat-label>MRN</mat-label>
        <input matInput placeholder="Medical Record Number" formControlName="mrn" />
        <mat-hint>External EMR ID</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>EMR GUID</mat-label>
        <input matInput placeholder="EMR Guid" formControlName="emr_guid" />
        <mat-hint>EMR Guid (if different)</mat-hint>
      </mat-form-field>
    </div>

    <div class="basicinfo-form-row">
      <mat-form-field appearance="outline">
        <mat-label>HubSpot Id</mat-label>
        <input matInput placeholder="HubSpot Id" formControlName="hubspot_id" />
        <mat-hint>Please fill hubSpot Id</mat-hint>
      </mat-form-field>
      <div class="provider-selector_container">
        <app-provider-selector-list [providerControl]="basicinfoForm.get('provider_id')"></app-provider-selector-list>
      </div>
    </div>
    <div class="basicinfo-form-row">
      <mat-form-field appearance="outline">
        <mat-label>Notes</mat-label>
        <textarea matInput placeholder="Notes" formControlName="notes" rows="4"></textarea>
        <mat-hint>Please fill Notes</mat-hint>
      </mat-form-field>
    </div>
    <h3>Patient time zone</h3>
    <div class="basicinfo-form-row">
      <ng-moment-timezone-picker [customPlaceholderText]="'Choose time zone'" [formControlName]="'timezone'"> </ng-moment-timezone-picker>
    </div>
    <h3>Programs Status</h3>
    <ng-container formGroupName="status">
      <div class="basicinfo-form-row">
        <mat-form-field appearance="legacy">
          <input
            class="search-patient-input"
            type="text"
            placeholder="Add program"
            aria-describedby="Add program to patient"
            matInput
            formControlName="programInput"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let program of filteredProgramOptions$ | async" [value]="program?.value" (click)="selectProgram(program.value)">
              <span>{{ program?.name }} ({{ program.value?.toUpperCase() }})</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="basicinfo-form-row">
        <mat-form-field class="chip-list" appearance="fill">
          <mat-chip-list #chipList placeholder="Add programs">
            <mat-chip *ngFor="let program of programs" [selectable]="true" [removable]="true" matTooltip="{{ program.name }}" (removed)="removeProgram(program.value)" selected>
              {{ program?.value?.toUpperCase() }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </mat-form-field>
      </div>
      <h3>Patient Status</h3>
      <div class="basicinfo-form-row">
        <mat-form-field appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select formControlName="label">
            <mat-option *ngFor="let status of statusOptions$ | async" [value]="status">
              {{ status }}
            </mat-option>
          </mat-select>
          <mat-hint>Please select patient status</mat-hint>
        </mat-form-field>
      </div>
    </ng-container>
    <h3>Contact Consent</h3>
    <div formGroupName="contact_consent" class="basicinfo-form-row">
      <mat-slide-toggle color="primary" labelPosition="before" formControlName="email"> Email </mat-slide-toggle>
      <mat-slide-toggle color="primary" labelPosition="before" formControlName="phone"> Phone </mat-slide-toggle>
      <mat-slide-toggle color="primary" labelPosition="before" formControlName="sms"> SMS </mat-slide-toggle>
    </div>
    <div class="basicinfo-field-container">
      <h3>Secondary providers</h3>
      <div class="basicinfo-form-row">
        <div>
          <ul *ngIf="patient.secondary_providers?.length > 0">
            <li matTooltip="{{ provider.email }}" *ngFor="let provider of patient.secondary_providers">{{ provider.name }} - {{ provider.role }}</li>
          </ul>
          <a (click)="openSecondaryProviderPanel()" color="accent"><span class="patient-open-dialog">Add secondary providers</span></a>
        </div>
      </div>
    </div>
  </form>
  <div class="basicinfo-form-row save-button">
    <button mat-raised-button *ngIf="showChangeEmailButton" color="accent" [disabled]="!showChangeEmailButton" (click)="openChangeEmailDialog()">
      <mat-icon>email</mat-icon> Change Email
    </button>
    <button mat-raised-button color="primary" [disabled]="basicinfoForm.invalid" (click)="saveBasicInfo()">Save</button>
  </div>
</mat-card>
