import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContextMenuOption } from 'src/app/models/context-menu-option.model';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit {
  @Input() options: ContextMenuOption[] = [];
  visible = false;
  x = 0;
  y = 0;
  unsubscribe = new Subject();
  darkWelbyMenuColor = '#ffffff';
  lightWelbyMenuColor = '#dfeef2';
  menuColor = this.darkWelbyMenuColor;

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.listenChangeTheme();
  }

  open(event: MouseEvent) {
    // Get mouse position
    event.preventDefault();
    this.x = event.clientX;
    this.y = event.clientY;
    this.visible = true;
  }

  close() {
    this.visible = false;
  }

  listenChangeTheme(): void {
    this.themeService.isLightModeObservable$.pipe(takeUntil(this.unsubscribe)).subscribe((isLightMode) => {
      this.menuColor = isLightMode ? this.lightWelbyMenuColor : this.darkWelbyMenuColor;
    });
  }
}
