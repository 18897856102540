<ng-container [ngSwitch]="measureType">
  <ng-template [ngSwitchCase]="vitalCategory.BLOOD_PRESSURE">
    <ng-container *ngIf="measurement?.value!!; else standardDual">
      {{ measurement?.value?.sbp | number : '1.0-0' }} / {{ measurement?.value?.dbp | number : '1.0-0' }}
      @
      {{ measurement?.value?.pulse_bpm | number : '1.0-0' }}
      BPM
    </ng-container>
    <ng-template #standardDual>
      {{ measurement?.value1 | number : '1.0-0' }} / {{ measurement?.value2 | number : '1.0-0' }}
      {{ measurement?.units }}
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="vitalCategory.BLOOD_GLUCOSE">
    <ng-container *ngIf="measurement?.value!!; else standardSingle">
      {{ measurement?.value?.mgdl | number : '1.0-0' }} {{ measurement?.units }} / A1C
      {{ measurement?.value?.a1c?.toFixed(3) || getA1CfromeAG(measurement?.value?.mgdl) }}
    </ng-container>
    <ng-template #standardSingle>
      {{ measurement?.value1 | number : '1.0-0' }}
      {{ measurement?.units }} / A1C
      {{ getA1CfromeAG(measurement?.value1) }}
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="vitalCategory.WEIGHT">
    <ng-container *ngIf="measurement?.value!!; else standardWeight"> {{ measurement?.value?.lbs | number : '1.0-0' }} LBS </ng-container>
    <ng-template #standardWeight>
      {{ measurement?.value1 | number : '1.0-0' }}
      {{ measurement?.units }}
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="vitalCategory.PULSE_OX">
    <ng-container *ngIf="measurement?.value!!; else standardPulseOx"> {{ measurement?.value?.spo2 | number : '1.0-0' }} %  /  {{ measurement?.value?.pulse_bpm | number : '1.0-0' }} bpm </ng-container>
    <ng-template #standardPulseOx>
      {{ measurement?.value1 | number : '1.0-0' }}
      {{ measurement?.units }}
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="vitalCategory.SLEEP">
    <ng-container *ngIf="measurement?.value!!; else standardSleep">
      {{ measurement?.value?.efficiency | number : '1.0-0' }} %
    </ng-container>
    <ng-template #standardSleep>
      {{ measurement?.value1 | number : '1.0-0' }}
      {{ measurement?.units }}
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="vitalCategory.TEMPERATURE">
    <ng-container *ngIf="measurement?.value!!; else standardTemp"> {{ measurement?.value?.temp | number : '1.0-0' }} F </ng-container>
    <ng-template #standardTemp>
      {{ measurement?.value1 | number : '1.0-0' }}
      {{ measurement?.units }}
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="vitalCategory.PULSEBPM">
    <ng-container *ngIf="measurement?.value!!; else standardBpm"> {{ measurement?.value?.pulse_bpm | number : '1.0-0' }} Bpm </ng-container>
    <ng-template #standardBpm>
      {{ measurement?.value1 | number : '1.0-0' }}
      {{ measurement?.units }}
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="vitalCategory.PEAKFLOW">
    <ng-container *ngIf="measurement?.value!!; else standardPF"> {{ measurement?.value?.pef | number : '1.0-0' }} L/min </ng-container>
    <ng-template #standardPF>
      {{ measurement?.value1 | number : '1.0-0' }}
      {{ measurement?.units }}
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="vitalCategory.FORCEDAIR">
    <ng-container *ngIf="measurement?.value!!; else standardFEV"> {{ measurement?.value?.fev | number : '1.0-0' }} L </ng-container>
    <ng-template #standardFEV>
      {{ measurement?.value1 | number : '1.0-0' }}
      {{ measurement?.units }}
    </ng-template>
  </ng-template>
  <ng-template ngSwitchDefault>
    {{ measurement?.value1 | number : '1.0-0' }}
    {{ measurement?.units }}
  </ng-template>
</ng-container>
