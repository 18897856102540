import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackService } from 'src/app/services/snack.service';
import { SetMainProviderDialogService, SetMainProviderServiceRequest } from './set-main-provider-dialog.service';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-set-main-provider-dialog',
  templateUrl: './set-main-provider-dialog.component.html',
  styleUrls: ['./set-main-provider-dialog.component.scss']
})
export class SetMainProviderDialogComponent implements OnInit {
  npi: string;
  teamMemberData = { first_name: '', last_name: '', phone: '', fax: '' };
  showNPIForm = false;
  unsubscribe$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
        private snackService: SnackService,
        private authService: NewAuthService,
        private setMainProviderService: SetMainProviderDialogService,
        private firestoreService: FirestoreService,
        private dialogRef: MatDialogRef<SetMainProviderDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  openNPIForm(show?: boolean): void {
    this.showNPIForm = show ?? !this.showNPIForm;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  setTeamMemberData(data) {
    this.teamMemberData = data;
  }

  setNpi(data: string): void {
    this.npi = data;
  }

  onSave(): void {
    const data: SetMainProviderServiceRequest  = {
      clientId: this.data.client.id,
      npi: this.npi,
      bulkLoadRecord: {
        action: 'loadSetMainProvider',
        triggerBy: this.authService.user.firstName + ' ' + this.authService.user.lastName,
        userId: this.authService.user.user_id,
      }
    }
    
    this.setMainProviderService.setMainProvider(data).subscribe({
      next: (res) => {
        this.firestoreService.col('admin_bulk_load_executions').doc(res.id).valueChanges().pipe(takeUntil(this.unsubscribe$)).subscribe({
          next: (data) => {
            if(data && data['status'] === 'processing'){
              this.snackService.genericSnackBar('Adding the main provider is in progress...!', ['warn-snackbar'], 5000);
              this.dialogRef.close();
            }else if(data && data['status'] === 'completed'){
              this.snackService.genericSnackBar('Main provider updated successfully!', ['success-snackbar'], 5000);
            }
          },
          error: (err) => {
            this.snackService.genericSnackBar('Error updating main provider!', ['error-snackbar'], 5000);
          }
        });
      },
      error: (err) => {
        this.snackService.genericSnackBar('Error updating main provider!', ['error-snackbar'], 5000);
      },
    });
  }
}
