import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { NewProviderSignupService } from 'src/app/auth/new-provider-signup/new-provider-signup.service';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-search-npi',
  templateUrl: './search-npi.component.html',
  styleUrls: ['./search-npi.component.scss'],
})

export class SearchNpiComponent implements OnInit {
  @Output() teamMemberData = new EventEmitter<{ first_name: '', last_name: '', organization:'', phone: '', fax: '' }>();
  @Output() showNPIForm = new EventEmitter<boolean>();
  @Output() npi = new EventEmitter<string>();
  npiFormGroup: FormGroup;
  isLoading = false;
  npiError: string;
  unsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder, 
    private newProviderService: NewProviderSignupService, 
    private snackService: SnackService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.npiFormGroup = this.fb.group({
      npi: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
    });
  }

  onClose(): void {
    this.showNPIForm.emit(false);
  }

  searchNpi() {
    this.isLoading = true;
    this.npiError = null;
    this.newProviderService
      .getNpiData(this.npiFormGroup.value.npi)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          this.snackService.genericSnackBar(err, ['error-snackbar'], 5000);
          console.log(err);
          this.isLoading = false;
          return of(null);
        })
      )
      .subscribe((data) => {
        if (Object.keys(data).indexOf('Errors') !== -1) {
          this.npiError = data['Errors'][0].description;
          this.snackService.genericSnackBar(this.npiError);
        } else {
          this.teamMemberData.emit({
            first_name: data.results[0]?.basic?.authorized_official_first_name ?? data.results[0]?.basic?.first_name,
            last_name: data.results[0]?.basic?.authorized_official_last_name ?? data.results[0]?.basic?.last_name,
            organization: data.results[0]?.basic?.organization_name ?? 'N/A',
            phone: this.getPhoneFromNPI(data),
            fax: data.results[0]?.addresses[0]?.fax_number ? data.results[0]?.addresses[0]?.fax_number : '-',
          });
          this.npi.emit(this.npiFormGroup.value.npi);
        }
        this.isLoading = false;
        this.showNPIForm.emit(false);
      });
  }

  private getPhoneFromNPI(data) {
    if (data.results[0]?.basic?.authorized_official_telephone_number) {
      return data.results[0]?.basic?.authorized_official_telephone_number.includes('-')
        ? data.results[0]?.basic?.authorized_official_telephone_number
        : this.utilsService.getPhoneNumberFormated(data.results[0]?.basic?.authorized_official_telephone_number);
    } else {
      return data.results[0]?.addresses[0]?.telephone_number.includes('-')
        ? data.results[0]?.addresses[0]?.telephone_number
        : this.utilsService.getPhoneNumberFormated(data.results[0]?.addresses[0]?.telephone_number);
    }
  }
}
