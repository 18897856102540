// this is the data on the patient record to indicate flag triggering
export interface PatientFlagCriteria {
  active: boolean; // whether the flag is active
  type: string; // alert type (i.e. Weight, BP, etc.)
  alert_patient: boolean; // whether an alert to patient should be sent
  alert_provider: boolean; // whether an alert to provider should be sent
  tier: number;
  comparitor: 'measures' | 'days' | 'fixed'; // whether you are comparing to a number of measures, a number of days or a fixed amount
  comparitor_type: 'percent' | 'number'; // whether the vital is either a pecent higher or a straight number higher
  count: number; // either the number of measures or the number of days to include in the average
  id?: string;
  notes: string; // any explanation about the design of the flag
  offset_amount: number; // the number of +/- when comparing a value
  offset_type: '+' | '-'; // whether to add or subtract the value
  operator: '<' | '==' | '>' | '<=' | '>='; // comparing greater than, less than, etc
  priority: number; // rank of most important pririties to check first. Needed if we stop after one alert
  secondary: boolean; // whether this need to compare the primary or secondary
  turn_offset_type?: boolean;
}

// this is the data actually storing on the vital flag table.
export interface VitalFlag {
  alert_category: string;
  alert_date: any;
  alert_patient: boolean;
  alert_provider: boolean;
  alert_variance: number;
  client_id: string;
  measurement_id: string;
  measurement_value1: number;
  measurement_value2: number;
  units: string;
  patient_id: string;
  resolved: boolean;
  resolve_date?: any;
  resolved_by?: string;
  invalid: boolean;
  expired: boolean;
  disposition: string;
  tier?: number;
  patient?: { firstName: string; lastName: string; id: string; provider_id: string };
  id?: string;
}

export enum VitalFlagTier {
  TIER_1 = 1,
  TIER_2 = 2,
  TIER_3 = 3,
}

export interface SearchAlertFlags {
  totalRecords: number;
  records: VitalFlag[];
  nextPage: number;
}
