<div class="setMainProviderDialog">
    <div class="setMainProviderDialog__header">
      <h2 class="setMainProviderDialog__title" mat-dialog-title>Set Main Provider</h2>
      <button mat-raised-button color="primary" (click)="openNPIForm()">Search by NPI <mat-icon>search</mat-icon></button>
    </div>
    <div class="setMainProviderDialog__content" mat-dialog-content>
      <div class="setMainProviderDialog__section" *ngIf="showNPIForm">
        <app-search-npi (teamMemberData)="setTeamMemberData($event)" (showNPIForm)="openNPIForm($event)" (npi)="setNpi($event)"></app-search-npi>
      </div>
      <div class="setMainProviderDialog__section" *ngIf="teamMemberData.first_name">
        <h3 class="setMainProviderDialog__subtitle">Basic Information</h3>
        <div class="setMainProviderDialog__column">
            <p class="setMainProviderDialog__label">First name: {{teamMemberData.first_name}}</p>
            <p class="setMainProviderDialog__label">Last name: {{teamMemberData.last_name}}</p>
            <p class="setMainProviderDialog__label">Phone: {{teamMemberData.phone}}</p>
            <p class="setMainProviderDialog__label">Fax: {{teamMemberData.fax}}</p>
        </div>
      </div>
    </div>
  
    <div class="setMainProviderDialog__actions">
      <button mat-button (click)="onClose()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="!teamMemberData.first_name" (click)="onSave()">Save</button>
    </div>
</div>
  