<form class="careteam_form" [formGroup]="teamMemberForm">
  <div class="careteam_form__header">
    <h2 class="careteam_form__title" mat-dialog-title>{{ data.isNew ? 'Add New Team Member' : 'Edit Team Member' }}</h2>
    <button mat-raised-button color="primary" (click)="openNPIForm()">Search by NPI <mat-icon>search</mat-icon></button>
  </div>
  <div class="careteam_form__content" mat-dialog-content>
    <div class="careteam_form__section" *ngIf="showNPIForm">
      <app-search-npi (teamMemberData)="setTeamMemberData($event)" (showNPIForm)="hideNPIForm($event)"></app-search-npi>
    </div>
    <div class="careteam_form__section">
      <h3 class="careteam_form__subtitle">Basic information</h3>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="first_name" required />
          <mat-error>First name is reguired</mat-error>
        </mat-form-field>

        <mat-form-field class="careteam_form__field">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="last_name" required />
          <mat-error>Last name is required</mat-error>
        </mat-form-field>
      </div>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>Organization</mat-label>
          <input matInput placeholder="Organization" formControlName="organization" />
        </mat-form-field>
      </div>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>Phone Number</mat-label>
          <span class="careteam_form__sms-prefix" matPrefix>+1</span>
          <input matInput placeholder="Team Member Phone" formControlName="phone" appPhoneMask />
        </mat-form-field>

        <mat-form-field class="careteam_form__field">
          <mat-label>Email Address</mat-label>
          <input matInput placeholder="Team Member Email Address" formControlName="email" />
        </mat-form-field>
      </div>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>Speciality</mat-label>
          <input matInput formControlName="speciality" />
        </mat-form-field>

        <mat-form-field class="careteam_form__field">
          <mat-label>Select</mat-label>
          <mat-select formControlName="type" required>
            <mat-option *ngFor="let type of types" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>Degree</mat-label>
          <input matInput formControlName="degree" />
        </mat-form-field>

        <mat-form-field class="careteam_form__field">
          <mat-label>Fax</mat-label>
          <span class="careteam_form__sms-prefix" matPrefix>+1</span>
          <input matInput placeholder="Team Member Fax Number" formControlName="fax" appPhoneMask />
        </mat-form-field>
      </div>
      <div class="careteam_form__row">
        <mat-form-field class="careteam_form__field">
          <mat-label>Comments</mat-label>
          <textarea matInput rows="3" formControlName="comments"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="careteam_form__section athena_section" *ngIf="isAthenaEMR$ | async">
      <h3 class="careteam_form__subtitle">Athena information</h3>
      <div class="careteam_form__row search-container">
        <mat-form-field class="careteam_form__field">
          <mat-label>Provider ID</mat-label>
          <input matInput formControlName="athena_provider_id" />
          <mat-hint>Select a provider ID, if nothing is configured, the provider will be taken from client configuration</mat-hint>
        </mat-form-field>
        <button
          mat-icon-button
          (click)="searchAthenaProvider()"
          [disabled]="isSearching || teamMemberForm.get('last_name').value === ''"
          matTooltip="Search provider in Athena"
          class="search-button"
        >
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <mat-progress-bar *ngIf="isSearching" class="search-progress" mode="indeterminate"></mat-progress-bar>
      <div class="search-results" *ngIf="athenaProviders.length > 0">
        <h3>Select provider</h3>
        <mat-selection-list #shoes [multiple]="false">
          <mat-list-option *ngFor="let provider of athenaProviders" [value]="provider" (click)="selectProvider(provider)">
            {{ provider.firstname }} {{ provider.lastname }} (ProviderID: {{ provider.providerid }})
          </mat-list-option>
          <mat-list-option *ngIf="!athenaProviders.length">No providers found</mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <div class="careteam_form__section">
      <div class="careteam_form__row">
        <div class="careteam_form__report">
          <h3 class="careteam_form__subtitle">Select report configuration</h3>
          <mat-checkbox formControlName="ccm">CCM Reports</mat-checkbox>
          <mat-checkbox formControlName="rpm">RPM Reports</mat-checkbox>
          <mat-form-field class="careteam_form__field">
            <mat-label>Method</mat-label>
            <mat-select formControlName="method" required>
              <mat-option *ngFor="let method of contactMethods" [value]="method">
                {{ method }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="careteam_form__section">
      <div class="careteam_form__report">
        <div class="container-toggle">
          <h3 class="careteam_form__subtitle">Select bill configuration</h3>

          <mat-slide-toggle formControlName="activeBill" (change)="onToggleChange()">Custom</mat-slide-toggle>
        </div>

        <mat-checkbox formControlName="ccmBill" [disabled]="!teamMemberForm.get('activeBill').value">CCM</mat-checkbox>
        <mat-checkbox formControlName="rpmBill" [disabled]="!teamMemberForm.get('activeBill').value">RPM</mat-checkbox>
      </div>
    </div>
  </div>

  <div class="careteam_form__actions">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="teamMemberForm.invalid" (click)="this.save()" cdkFocusInitial>Save</button>
  </div>
</form>
