import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { ContextMenuComponent } from './context-menu.component';
import { ContextMenuOption } from 'src/app/models/context-menu-option.model';

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  private contextMenuRef?: ComponentRef<ContextMenuComponent>;
  private currentData?: any;

  constructor(private resolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {}

  openMenu(event: MouseEvent, options: ContextMenuOption[], data: any) {
    event.preventDefault();
    this.closeMenu();

    this.currentData = data;

    const factory = this.resolver.resolveComponentFactory(ContextMenuComponent);
    this.contextMenuRef = factory.create(this.injector);
    this.contextMenuRef.instance.options = options.map((option) => ({
      ...option,
      action: () => option.action(this.currentData), // Pass data when clicking
    }));

    this.contextMenuRef.instance.open(event);
    this.appRef.attachView(this.contextMenuRef.hostView);
    document.body.appendChild(this.contextMenuRef.location.nativeElement);

    setTimeout(() => {
      document.addEventListener('click', this.closeMenu, { once: true });
    });
  }

  closeMenu = () => {
    if (this.contextMenuRef) {
      this.appRef.detachView(this.contextMenuRef.hostView);
      this.contextMenuRef.destroy();
      this.contextMenuRef = undefined;
    }
  };
}
