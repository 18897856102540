<form class="medications-form" *ngIf="medicationForm && medicationForm.controls" [formGroup]="medicationForm" (ngSubmit)="addMedication()">
  <h2>{{ title }}</h2>
  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Drug Name</mat-label>
      <input type="text" matInput formControlName="name" [matAutocomplete]="auto" placeholder="Enter at least 3 characters" />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div faLayout="row">
    <mat-form-field class="amount-form-field" >
      <mat-label>Amount</mat-label>
      <input type="text" matInput formControlName="dosageAmount" />
    </mat-form-field>

    <mat-form-field  class="units-form-field"  >
      <mat-label>Units</mat-label>
      <mat-select formControlName="dosageMeasure">
        <mat-option *ngFor="let dose of doses" [value]="dose">
          {{ dose }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    
  </div>
  <div >
    <mat-form-field class="drug-form-field" >
      <mat-label>Drug Form</mat-label>
      <mat-select formControlName="drugForm">
        <mat-option *ngFor="let type of doseTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
<div class="frequency-container">
  <mat-form-field class="frequency-form-field" >
    <mat-label>Frequency</mat-label>
    <mat-select formControlName="frequency" >
      <mat-option *ngFor="let option of frequencyOptions" [value]="option.value" matTooltip="{{option.name}}">
        {{ option.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="medicationForm.get('frequency')?.value === 'Other'">
    <mat-form-field class="input-form-field">
      <mat-label>Other</mat-label>
      <input matInput formControlName="otherFrequency" placeholder="Enter frequency" />
    </mat-form-field>
  </div>
</div>
  

<div>
  <label>Hours</label>
    <mat-radio-group formControlName="selectedHours" class="radio-group">
      <mat-radio-button *ngFor="let hour of hours" [value]="hour">
        {{ hour }}
      </mat-radio-button>
    </mat-radio-group>
</div>

  <div class="medication-form-buttons">
    <button mat-raised-button color="default" type="button" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="medicationForm?.invalid" type="submit">Save</button>
  </div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>
</form>
