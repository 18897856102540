<form class="npi_form" [formGroup]="npiFormGroup" (ngSubmit)="searchNpi()">
  <mat-form-field appearance="outline">
    <mat-label>Enter NPI</mat-label>
    <input #npiInput matInput placeholder="NPI" formControlName="npi" required />
  </mat-form-field>
  <div class="npi_form__buttons">
    <button mat-button type="button" (click)="onClose()">Close</button>
    <button [disabled]="npiFormGroup.invalid" mat-raised-button color="primary" type="submit">Load Information</button>
  </div>
  <mat-error style="margin-top: 1rem">
    <span *ngIf="npiError">
      {{ npiError }}
    </span>
  </mat-error>
</form>