import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export interface SetMainProviderServiceRequest {
  clientId: string;
  npi: string;
  bulkLoadRecord: {action: string, triggerBy: string, userId: string};
}

@Injectable({
  providedIn: 'root',
})
export class SetMainProviderDialogService {

    constructor(private http: HttpClient) { }

    setMainProvider(body: SetMainProviderServiceRequest): Observable<any> {
      const url = `${environment.welbyEndpoint}/api/v1/bulk-load/set-default-provider-client`;
      return this.http.post(url, body);
    }
}