import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';

import * as version from '../assets/version.json';
import { NewAuthService } from './services/auth/new-auth-service.service';
import { DialogService } from './services/dialog.service';
import { LoaderService } from './services/loader.service';
import { SnackService } from './services/snack.service';
import { AreYouSureModalComponent } from './shared/are-you-sure-modal/are-you-sure-modal.component';

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'welby-web';
  isLoading$ = this.loadingService.isLoadingObservable$;

  constructor(
    private db: AngularFirestore,
    private dialog: MatDialog,
    private swUpdate: SwUpdate,
    private authService: NewAuthService,
    private snackService: SnackService,
    private dialogService: DialogService,
    private loadingService: LoaderService
  ) {}

  ngOnInit(): void {
    this.authService.needsTakeOverObservable$.subscribe((needsTakeOver) => {
      if (needsTakeOver) {
        this.openEmailNotVerifiedDialog();
      }
    });
  }

  async ngAfterViewInit() {
    // add a 2 second wait
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const webAppVersionSnap = await this.db.collection('_version_').doc('web-app').get().toPromise();
    if (webAppVersionSnap.exists) {
      const major = parseInt(webAppVersionSnap.data()['major'], 10);
      const minor = parseInt(webAppVersionSnap.data()['minor'], 10);
      const patch = parseInt(webAppVersionSnap.data()['patch'], 10);
      if (this.swUpdate.isEnabled) {
        this.swUpdate.available.subscribe(() => {
          const modal = this.dialog.open(AreYouSureModalComponent, {
            data: {
              title: `New version available! ${major}.${minor}.${patch}`,
              body: `There is a new version of the web app available. Please refresh the page. Your current version is : ${version.major}.${version.minor}.${version.patch}`,
              hideCancelBtn: false,
              cancelButton: `I'll refresh later`,
              confirmButton: 'Refresh Now',
            },
          });
          // refresh the page after closed
          modal.afterClosed().subscribe((resp) => {
            if (resp) {
              window.location.reload();
            }
          });
        });
      }
    }
  }

  openEmailNotVerifiedDialog() {
    const dialog = this.dialogService.openEmailNotVerfiedDialog();
    dialog.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.authService.sendVerificationEmailForProvider(
          this.authService.user.email,
          this.authService.user.client_responsible_id,
          this.authService.user.user_id,
          this.authService.user.firstName,
          this.authService.user.lastName
        );
        this.snackService.genericSnackBar('Verification email sent!', ['success-snackbar'], 5000);
        this.openEmailNotVerifiedDialog();
      } else {
        this.authService.signOut();
      }
    });
  }
}
