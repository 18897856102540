import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable, Subject, combineLatest, forkJoin, from, merge } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { ClaimCode } from 'src/app/models/billing/claims-codes-model';
import { Client } from 'src/app/models/client.model';
import { EmrDemographics } from 'src/app/models/emr/emr-data.model';
import { Diagnosis } from 'src/app/models/fhir-models';
import { Patient } from 'src/app/models/patient.model';
import { VitalFlag } from 'src/app/models/vitals/vital-flag.model';
import { NewAuthService } from '../auth/new-auth-service.service';
import { FirestoreService } from '../firestore.service';
import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root',
})
export class InitialDataModel {
  maxPatientLimit2ShowTour = 3;
  maxClientAccountsByProvider = 8;
  clinicalAlerts$: Observable<VitalFlag[]> | any;
  clinicalAlertsSubject: BehaviorSubject<VitalFlag[]> = new BehaviorSubject(null);
  clinicalAlertsObs$: Observable<VitalFlag[]> = this.clinicalAlertsSubject.asObservable();
  clientPanel$: Observable<Patient[]>;
  clients$: Observable<Client[]>;
  clientProviderPanel$: Observable<Patient[]>;
  unsubscribe;
  alertsUnsubscribe;

  clientPanel: Patient[]; // those patients assigned to this provider
  clientAccounts: string[];
  clients = {};
  cptCodes: ClaimCode[];
  cptLookup = {};
  clientPanelIDArray = [];
  emrList = [];
  allClientUsers = {};
  patient_dx_list = {};
  patientTest: Patient;
  isTourShownSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isTourShownObservable$: Observable<boolean> = this.isTourShownSubject.asObservable();

  constructor(private auth: NewAuthService, private fsService: FirestoreService, private afAuth: AngularFireAuth, private utilsService: UtilsService) {
    this.auth.isLoggedObservable$.pipe(filter((isLogged) => isLogged === false)).subscribe((isLogged) => {
      if (!isLogged) {
        this.clientPanel = [];
        this.clientPanel$ = undefined;
      }
    });
  }

  async initialLoad() {
    if (this.unsubscribe) {
      this.clearSubscriptions();
    }
    this.unsubscribe = new Subject<void>();
    await this.checkIfClientAccountMappingExists();
    this.getPatients();
    this.getClients();
    this.getProviders();
    this.getEMR();
    this.getCPT();
    return true;
  }

  async getPatients(): Promise<void> {
    if (this.auth.user.roles.isAdmin) {
      this.clientPanel$ = this.fsService.colWithIds$('users', (ref) => ref.where('roles.isPatient', '==', true).orderBy('lastName'));
    } else {
      this.clientPanel$ = this.fsService.queryCollectionWithChunks$(
        'users',
        (ref) => ref.where('roles.isPatient', '==', true),
        'client_responsible_id',
        this.clientAccounts,
        this.maxClientAccountsByProvider,
        'lastName'
      );
    }
    const clientPanelMap$ = this.clientPanel$.pipe(
      take(2),
      map((panel: Patient[]) => {
        panel.forEach(async (patient) => {
          if (this.clientPanelIDArray.indexOf(patient.user_id) === -1) {
            this.clientPanelIDArray.push(patient.user_id);
          }
          this.allClientUsers[patient.user_id] = patient;
        });
        return panel;
      })
    );
    const needsTakeOver$ = this.auth.needsTakeOverObservable$;
    combineLatest([clientPanelMap$, needsTakeOver$])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([panel, needsTakeOver]) => {
        this.clientPanel = panel;
        const isStartTourShown = this.clientPanel.length < this.maxPatientLimit2ShowTour && !this.auth.user.roles.isAdmin && !needsTakeOver;
        this.isTourShownSubject.next(isStartTourShown);
      });
  }
  /**
   * Get all the client users mapping to the client accounts
   */
  async getClientAccounts(): Promise<{ user_id: string; client_account_id: string }[]> {
    const clientAccountMapping$ = this.fsService.colWithIds$('client_account_to_user', (ref) => ref.where('user_id', '==', this.auth.user.user_id));
    return clientAccountMapping$.pipe(take(1)).toPromise();
  }
  /**
   * This function is used to add legacy client accounts to the client_account_to_user collection
   */
  async checkIfClientAccountMappingExists(): Promise<void> {
    const clientAccountMapping = await this.getClientAccounts();
    if (clientAccountMapping.length > 0) {
      this.clientAccounts = clientAccountMapping.map((clientMap) => clientMap.client_account_id);
    } else {
      await this.addLegacyClientAccounts2Client2users();
    }
  }

  async getClients() {
    if (this.auth.user.roles.isAdmin) {
      this.clients$ = this.fsService
        .colWithIds$(`clients`)
        .pipe(map((response) => response.sort((clientA, clientB) => this.utilsService.sortClientsByAlphabeticOrder(clientA, clientB))));
    } else {
      const clientAccounts = this.clientAccounts;
      const clientAccountsRef = clientAccounts.map((client_account) => from(this.fsService.doc(`clients/${client_account}`).get()));
      this.clients$ = forkJoin([...clientAccountsRef]).pipe(map((docs) => docs.map((doc) => ({ ...doc.data(), id: doc.id }))));
    }
    this.clients$.pipe(take(1)).subscribe((clients: Client[]) => {
      clients.forEach((c) => {
        this.clients[c.id] = c;
      });
    });
  }

  getExternalPatientPanel() {
    return this.fsService.colWithIds$('users', (ref) => ref.where('user_account_access', 'array-contains', this.auth.user.user_id));
  }

  getEMR() {
    this.fsService
      .colWithIds$('ehr_vendors')
      .pipe(take(1))
      .subscribe((emr: EmrDemographics[]) => {
        const sortedList = emr.sort((a, b) => (a.display_name > b.display_name ? 1 : b.display_name > a.display_name ? -1 : 0));
        this.emrList = sortedList;
      });
  }

  getCPT() {
    this.fsService
      .colWithIds$('claims_cpt_codes')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((codes: ClaimCode[]) => {
        this.cptCodes = codes;
        codes.forEach((c) => {
          this.cptLookup[c.cpt_code] = c;
        });
      });
  }

  async getProviders() {
    this.allClientUsers[this.afAuth.auth.currentUser.uid] = this.auth.user; // just always grabbing yourself as a task option

    if (this.auth.user.roles.isWelbyClinical) {
      this.clientProviderPanel$ = this.fsService.colWithIds$('users', (ref) => ref.where('roles.isWelbyClinical', '==', true));
    } else {
      const clientAccountMap = await this.getClientAccounts();
      const clientCalls = clientAccountMap.map((clientAccount) =>
        this.fsService.colWithIds$('users', (ref) => ref.where('client_responsible_id', '==', clientAccount.client_account_id).where('roles.isClient', '==', true))
      );
      this.clientProviderPanel$ = merge(...clientCalls);
    }

    this.clientProviderPanel$.pipe(takeUntil(this.unsubscribe)).subscribe((panel: Patient[]) => {
      panel.forEach((provider) => {
        this.allClientUsers[provider.user_id] = provider;
      });
    });
  }

  async getPatientsDiagnoses(patientIds: string[]) {
    for (const patientId of patientIds) {
      if (!this.patient_dx_list[patientId]) {
        this.patient_dx_list[patientId] = [];
        const snapshot = await this.fsService.col('users').doc(patientId).collection('my_fhir_diagnoses').ref.get();
        if (!snapshot.empty) {
          snapshot.docs.forEach((s) => {
            // push diagnoses to patient account
            const dx = s.data() as Diagnosis;
            this.patient_dx_list[patientId].push(dx);
          });
        }
      }
    }
  }

  resetShowTour() {
    this.isTourShownSubject.next(false);
  }

  setTourShown() {
    this.isTourShownSubject.next(true);
  }

  clearSubscriptions() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  resetValues() {
    this.clientAccounts = undefined;
    this.clientPanel = [];
    this.clients = {};
    this.cptCodes = [];
    this.cptLookup = {};
    this.clientPanelIDArray = [];
    this.emrList = [];
    this.allClientUsers = {};
    this.patient_dx_list = {};
    this.patientTest = undefined;
  }

  getPatientStatusOptions(): Observable<string[]> {
    return this.fsService.doc$('welby_configuration/patient_status').pipe(map((status: { items: string[] }) => status.items));
  }

  /**
   * This is a temporary function to add legacy client accounts to the client_account_to_user collection
   */
  private async addLegacyClientAccounts2Client2users() {
    try {
      const legacyClientAccounts = this.auth.user.client_accounts;
      if (legacyClientAccounts.length > 0) {
        this.clientAccounts = legacyClientAccounts;
        for (const clientAccount of legacyClientAccounts) {
          await this.fsService.add('client_account_to_user', { client_account_id: clientAccount, user_id: this.auth.user.user_id });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}
