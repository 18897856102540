<mat-expansion-panel class="contact-expansion" [expanded]="expandPanel">
  <mat-expansion-panel-header>
    <mat-panel-title
      ><mat-icon class="contact-icon" *ngIf="contact">{{ getIconFromType(contact?.contact_type) }}</mat-icon
      ><span *ngIf="contactForm.get('contact_data')?.value?.length > 0"
        >+1 {{ contactForm.get('contact_data')?.value }}</span
      ></mat-panel-title
    >
    <button
      *ngIf="contactForm.get('contact_data')?.value"
      mat-icon-button
      matTooltip="Delete contact"
      color="warn"
      (click)="$event.stopPropagation(); deleteContact()"
    >
      <mat-icon>remove_circle_outline</mat-icon>
    </button>
  </mat-expansion-panel-header>
  <form class="contact-form-container" [formGroup]="contactForm">
    <div class="contact-form-row">
      <mat-form-field appearance="outline">
        <mat-label>Contact number</mat-label>
        <span class="contact-form-sms-prefix" matPrefix>+1</span>
        <input matInput placeholder="Contact number" formControlName="contact_data" appPhoneMask />
        <mat-error *ngIf="contactForm.get('contact_data').errors?.phoneNumber">Please, follow the format (+1 ###-###-####)</mat-error>
        <mat-hint>Please fill contact number</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-select formControlName="contact_type">
          <mat-option *ngFor="let contactType of phoneTypes" [value]="contactType">
            {{ contactType?.viewValue?.charAt(0).toUpperCase() + contactType?.viewValue?.substring(1).toLowerCase() }}
          </mat-option>
        </mat-select>
        <mat-hint>Please select contact type</mat-hint>
      </mat-form-field>
    </div>
    <div class="contact-form-row">
      <mat-checkbox formControlName="isPrimary">Select as primary phone</mat-checkbox>
    </div>
    <div class="contact-form-row">
      <mat-checkbox formControlName="isPrimaryForSms">Select as primary phone for sms</mat-checkbox>
    </div>
  </form>
  <div class="contact-form-row save-button">
    <button mat-raised-button color="primary" [disabled]="contactForm.invalid" (click)="saveContact()">Save</button>
  </div>
</mat-expansion-panel>
