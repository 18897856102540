<mat-card>
  <mat-card-header>
    <mat-card-title>Client Demographics</mat-card-title>
    <mat-card-subtitle>Account Information</mat-card-subtitle>
    <button mat-icon-button (click)="closeDetail()" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content *ngIf="client!!">
    <p class="client-name" (click)="copyKey2Clipboard(client?.client_ids?.uid)">Client: {{ client!! ? client.client_name : 'Unknown' }}</p>
    <p>Client ID: {{ client!! ? client.client_npi : 'Unknown' }}</p>
    <hr />
    <br />
    <h4>EMR Data</h4>
    <p>Client EMR: {{ client.emr_data!! ? data.getEMR(client.emr_data.emr_name) : 'N/A' }}</p>
    <p>Client EMR Access: {{ client.emr_data!! ? client.emr_data.emr_access : 'N/A' }}</p>
    <p>Client EMR Login: {{ client.emr_data!! ? client.emr_data.emr_login_id : 'N/A' }}</p>
    <hr />
    <br />
    <h4>Software License:</h4>
    <p>
      Active:
      {{ client?.services?.software_license ? client?.services?.software_license?.active : client?.software_license ? client?.software_license?.active : 'N/A' }}
    </p>
    <p>
      Rate: ${{
        client?.services?.software_license ? client?.services?.software_license?.rate?.toFixed(2) : client?.software_license ? client?.software_license?.rate?.toFixed(2) : 'N/A'
      }}
      PMPM
    </p>
    <br />
    <h4>RPM Services:</h4>
    <p>
      Active:
      {{ client.services?.rpm_clinical ? client.services?.rpm_clinical?.active : client?.rpm_clinical ? client?.rpm_clinical?.active : 'N/A' }}
    </p>
    <p>
      Rate: ${{ client?.services?.rpm_clinical ? client?.services?.rpm_clinical?.rate?.toFixed(2) : client?.rpm_clinical ? client?.rpm_clinical?.rate : 'N/A' }}
      PMPM
    </p>
    <br />
    <h4>CCM Services:</h4>
    <p>
      Active:
      {{ client.services?.ccm_clinical ? client.services?.ccm_clinical?.active : client?.ccm_clinical ? client?.ccm_clinical?.active : 'N/A' }}
    </p>
    <p>
      Rate: ${{ client?.services?.ccm_clinical ? client?.services?.ccm_clinical?.rate?.toFixed(2) : client?.ccm_clinical ? client?.ccm_clinical.rate?.toFixed(2) : 'N/A' }}
      PMPM
    </p>
    <hr />
    <br />
    <h4>Survey configuration</h4>
    <button mat-raised-button color="accent" type="button" (click)="openSurveyConfiguration()">Open configuration</button>
    <hr />
    <br />
    <h4>Set main provider</h4>
    <button mat-raised-button color="accent" type="button" (click)="openSetMainProvider()">Open configuration</button>
  </mat-card-content>
</mat-card>
