import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { startCase, toLower } from 'lodash';
import { Client } from 'src/app/models/client.model';
import { CoverageTypes, Patient, SecondaryProvider } from 'src/app/models/patient.model';
import { ClientServicesService } from 'src/app/services/admin/client-services.service';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { DataService } from 'src/app/services/data.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PatientService } from 'src/app/services/patient.service';
import { ProgramService } from 'src/app/services/programs/program.service';
import { ageValidator } from 'src/app/shared/validators/patient-age.validators';
import { environment } from 'src/environments/environment';
import { AddSecondariesProvidersComponent } from '../add-secondaries-providers/add-secondaries-providers.component';
import { InitialDataModel } from './../../../services/models/initial-data-model.service';
import { SnackService } from './../../../services/snack.service';
@Component({
  selector: 'app-patient-demographics-dialog',
  templateUrl: './patient-demographics-dialog.component.html',
  styleUrls: ['./patient-demographics-dialog.component.scss'],
})
export class PatientDemographicsDialogComponent implements OnInit, OnDestroy {
  // THIS IS THE NEW MINIMIZED PATIENT LOADING FORM.
  patientForm: FormGroup;
  serverMessage: '';
  owners = [];
  isLoading = false;
  clients$: Observable<Client[]>;
  timezoneConfig = {
    hideSelected: false,
    dropdownPosition: 'top',
    appearance: 'underline',
    clearOnBackspace: true,
    closeOnSelect: true,
    appendTo: null,
  };
  languageModel = { label: 'Language', showLabel: true, appearance: 'legacy', showOnlyActive: false };
  showSecondaryProviderPanel = false;
  secondaryProviders: SecondaryProvider[] = [];
  programs$: Observable<any[]>;
  filteredProgramOptions$: Observable<any[]>;
  availablePrograms: any[] = [];
  unsubscribe = new Subject<void>();
  prograsmValidator: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PatientDemographicsDialogComponent>,
    private dataService: DataService,
    public dataModel: InitialDataModel,
    public ds: DataService,
    private auth: NewAuthService,
    private afAuth: AngularFireAuth,
    private snack: SnackService,
    private patientService: PatientService,
    private router: Router,
    private fsService: FirestoreService,
    private clientService: ClientServicesService,
    private dialog: MatDialog,
    private programService: ProgramService
  ) {}
  ngOnInit(): void {
    this.clients$ = this.dataModel.clients$;
    this.initializeForm();
    this.getPrograms();
    this.setFilteredProgramOption();
  }

  getPrograms(): void {
    this.programs$ = this.programService.getPrograms();
    this.programs$.pipe(take(1), takeUntil(this.unsubscribe)).subscribe((programs) => {
      this.availablePrograms = programs;
      this.availablePrograms.forEach((programObj) => {
        (this.patientForm.get('status') as FormGroup).addControl(programObj.value, new FormControl(false, []));
      });
    });
  }

  get programs(): any[] {
    return (this.patientForm.get('status') as FormGroup)?.getRawValue()
      ? Object.keys((this.patientForm.get('status') as FormGroup)?.getRawValue())
          .filter(
            (key) =>
              this.availablePrograms.some((program) => (key === 'g0511' ? program.value === key : program.value.slice(0, 3) === key)) &&
              this.patientForm.get('status').get(key).value
          )
          .map((key) =>
            this.availablePrograms.find((program) => {
              if (key === 'rpm') {
                return program.value === this.patientForm.get('rpm_category').value;
              }
              if (key === 'g0511') {
                return program.value === key;
              }
              return program.value.slice(0, 3) === key;
            })
          )
      : [];
  }

  async loadPatientToDatabase() {
    this.isLoading = true;
    const patient = this.buildPatient();
    const canSavePatient = await this.canSavePatientAfterBillingRestriction();
    if (canSavePatient) {
      this.savePatient(patient);
    } else {
      this.showErrorNotification();
    }
  }

  async afterSaveActions(patient: Patient, response: any) {
    console.log('POST call successful value returned in body', response);
    await this.afAuth.auth.sendPasswordResetEmail(patient.email);
    this.snack.genericSnackBar(response.message, ['success-snackbar']);
    const id = response?.user_id;
    this.dialogRef.close();
    this.isLoading = false;
    await this.updateClientInfo(patient.client_responsible_id);
    if (this.data?.userProspect?.id) {
      await this.fsService.update(`users_prospective/${this.data.userProspect.id}`, { status: 'Enrolled' });
    }
    await this.saveFirstClinicalResolution({ ...patient, user_id: id });
    await this.router.navigateByUrl(`clinical/patient-detail/${id}`);
  }

  compareClientObjects(object1: any, object2: any) {
    return object1 && object2 && object1.client_id === object2.client_id;
  }

  setMaxDate(): Date {
    return new Date();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openSecondaryProviderPanel(): void {
    const dialogRef = this.dialog.open(AddSecondariesProvidersComponent, {
      width: '700px',
      data: { providers: this.secondaryProviders },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.secondaryProviders = result;
    });
  }

  setFilteredProgramOption(): void {
    this.filteredProgramOptions$ = this.patientForm.get('programInput').valueChanges.pipe(
      filter((data) => data && data.length > 0),
      map((data) => this.availablePrograms.filter((program) => program.name.toLowerCase().includes(data.toLowerCase()) || program.value.toLowerCase().includes(data.toLowerCase())))
    );
  }

  selectProgram(program: string): void {
    const normalizedProgram = program.slice(0, 3);
    if (program.includes('g05')) {
      (this.patientForm.get('status') as FormGroup).get('g0511').setValue(true);
      this.patientForm.get('programInput').reset();
    } else {
      (this.patientForm.get('status') as FormGroup).get(normalizedProgram).setValue(true);
      this.patientForm.get('programInput').reset();
      if (program.includes('rpm')) {
        this.patientForm.get('rpm_category').setValue(program);
      }
    }
  }

  removeProgram(program: string): void {
    const normalizedProgram = program.slice(0, 3);
    if (program.includes('g05')) {
      (this.patientForm.get('status') as FormGroup).get('g0511').setValue(false);
    } else {
      (this.patientForm.get('status') as FormGroup).get(normalizedProgram).setValue(false);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  programsValidator(control: AbstractControl): ValidationErrors | null {
    const values = Object.values(control.value);
    const isValid = values.some((value) => value === true);
    return isValid ? null : { noPrograms: true };
  }

  private initializeForm(): void {
    this.patientForm = this.fb.group({
      client: [this.data.userProspect?.client ?? '', [Validators.required]],
      firstName: [this.data.userProspect?.first_name ?? '', [Validators.required]],
      middleName: [this.data.userProspect?.midlle_name ?? ''],
      lastName: [this.data.userProspect?.last_name ?? '', [Validators.required]],
      dob: [this.data.userProspect?.dob ? new Date(this.data.userProspect?.dob) : '', [Validators.required, ageValidator]],
      email: [this.data.userProspect?.email ?? '', [Validators.email]],
      language: ['English'],
      provider: [this.data.userProspect?.emr_id ?? '', [Validators.required]],
      emr_id: ['', [Validators.required]],
      ssn_id: ['', [Validators.minLength(4), Validators.maxLength(4)]],
      notes: [this.data.userProspect?.notes ?? '', []],
      programInput: [''],
      rpm_category: [''],
      status: this.fb.group({ rpm: [false] }, { validators: this.programsValidator }),
      timezone: ['', Validators.required],
      alert_sms: ['', [Validators.minLength(10), Validators.pattern('[0-9]*')]],
      hubspot_id: [''],
    });
    this.clients$.subscribe((clients) => {
      const clientFromProspect = clients.find((client) => client.client_id === this.data.userProspect?.client_id);
      if (!this.patientForm.get('client').value) {
        this.patientForm.get('client').setValue(clientFromProspect);
      }
    });
  }

  private buildPatient(): Patient {
    const uid = Guid.create().toString();
    const userEmail = this.patientForm.value['email'] ? this.patientForm.value['email'] : `${uid}@getwelby.com`;
    const firstName = startCase(toLower(this.patientForm.value.firstName?.trim()));
    const lastName = startCase(toLower(this.patientForm.value.lastName?.trim()));
    const middleName = startCase(toLower(this.patientForm.value.middleName?.trim()));
    const alertLine = this.ds.returnProviderTwilioLine(this.patientForm.value.provider.user_id);

    return {
      user_account_access: [this.auth.user.user_id],
      client_accounts: [this.auth.user.client_responsible_id],
      alert_contacts: [{ type: 'provider', data: alertLine }],
      client_responsible: this.dataModel.clients[this.patientForm.value.client?.client_id ?? this.patientForm.value.client?.id]?.client_name ?? '',
      client_responsible_id: this.patientForm.value?.client?.client_id ?? this.patientForm.value.client?.id,
      provider_id: this.patientForm.value.provider.user_id,
      secondary_providers: this.secondaryProviders,
      dob: new Date(this.patientForm.value.dob),
      email: userEmail,
      emr_id: this.patientForm.value.emr_id,
      hubspot_id: this.patientForm.value.hubspot_id,
      firstName,
      middleName,
      gender: 'other',
      height_inches: 0,
      lastName,
      language: this.patientForm.value.language,
      notes: this.patientForm.value.notes,
      pregnancies: 0,
      search_terms: [
        firstName.toLowerCase(),
        lastName.toLowerCase(),
        userEmail.toLowerCase(),
        this.dataService.getFirstName(this.patientForm.value.provider.user_id).toLowerCase(),
        this.dataService.getLastName(this.patientForm.value.provider.user_id).toLowerCase(),
      ],
      status: this.patientForm.value.status,
      source: {
        manual: true,
        sheet: 'N/A',
        tab: 'N/A',
        user: this.auth.user.email,
      },
      ssn_id: this.patientForm.value.ssn_id,
      weight_lbs: 0,
      providerGenerated: true,
      timezone: this.patientForm.get('timezone').value,
      primary_coverage_type: CoverageTypes.OTHER,
    };
  }

  private async canSavePatientAfterBillingRestriction(): Promise<boolean> {
    const currentUser = this.auth.user;
    const currentClient = (await this.clientService.getClientById(currentUser.client_responsible_id)).data();
    if (currentClient?.billing_approved) {
      return true;
    } else {
      return currentClient?.patients_loaded >= 0 ? currentClient.patients_loaded < environment.trial_patient_loads : true;
    }
  }

  private async updateClientInfo(clientId?: string) {
    const currentClientId = clientId ?? this.auth.user?.client_responsible_id;
    const currentClient = (await this.clientService.getClientById(currentClientId)).data();
    if (currentClient.patients_loaded >= 0) {
      this.clientService.updateClient(currentClientId, {
        client_name: currentClient.client_name,
        patients_loaded: currentClient.patients_loaded + 1,
      });
    } else {
      this.clientService.updateClient(currentClientId, { client_name: currentClient.client_name, patients_loaded: 1 });
    }
  }

  private showErrorNotification(): void {
    this.snack.genericSnackBar('Error: Exceed the number of patients. Contact administrator.', ['error-snackbar'], 5000);
    this.isLoading = false;
    this.dialogRef.close();
  }

  private savePatient(patient: Patient): void {
    this.patientService.createPatient(patient).subscribe(
      (val) => {
        this.afterSaveActions(patient, val);
      },
      (error) => {
        console.log('POST really screwed something up', error);
        console.error(error);
        this.snack.genericSnackBar(error?.error?.message, ['error-snackbar'], 5000);
        this.dialogRef.close();
        this.isLoading = false;
      },
      () => {
        console.log('The POST observable is now completed.');
      }
    );
  }

  private async saveFirstClinicalResolution(patient: Patient): Promise<void> {
    try {
      const clinicalResolution = {
        assessor: this.auth.user.user_id,
        content: this.buildClinicalResolution(patient),
        follow_up: 'Initial Patient Onboarding',
        associations: {
          uid: patient.user_id,
          client_id: this.auth.user.client_responsible_id,
        },
        category: '',
        date: new Date(),
      };
      await this.patientService.addClinicalResolution(clinicalResolution).pipe(take(1)).toPromise();
    } catch (error) {
      console.error(error);
      this.snack.genericSnackBar('Error: Unable to save clinical resolution', ['error-snackbar'], 5000);
    }
  }

  private buildClinicalResolution(patient: Patient) {
    let clinicalResolution = `<h2>Consent</h2>`;
    if (patient?.status) {
      clinicalResolution += `<p>Patient agreed to informed consent of the following programs:</p><ul>`;
      Object.entries(patient.status).forEach(([key, value]) => {
        if (value) {
          clinicalResolution += `<li>${key.toUpperCase()}</li>`;
        }
      });
      clinicalResolution += `<p>Informed patient of the availability of various Welby services, possible cost sharing responsibilities,`;
      clinicalResolution += ` that only one practitioner can furnish and bill services during a calendar month,`;
      clinicalResolution += ` and their right stop services at any time</p>`;
    }
    return clinicalResolution;
  }
}
