import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { NewProviderSignupService } from 'src/app/auth/new-provider-signup/new-provider-signup.service';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-number.validator';

@Component({
  selector: 'app-careteam-dialog',
  templateUrl: './careteam-dialog.component.html',
  styleUrls: ['./careteam-dialog.component.scss'],
})
export class CareteamDialogComponent implements OnInit, OnDestroy {
  teamMemberForm: FormGroup;
  npiFormGroup: FormGroup;
  types = ['PCP', 'Specialist', 'Family Member', 'Home Health / Caregiver', 'Other'];
  contactMethods = ['fax', 'email', 'none'];
  isLinear = true;
  isLoading = false;
  npiError: string;
  showNPIForm = false;
  unsubscribe$ = new Subject();

  constructor(
    private dialogRef: MatDialogRef<CareteamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private newProviderService: NewProviderSignupService,
    private snackService: SnackService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.npiFormGroup = this.fb.group({
      npi: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
    });
    this.teamMemberForm = this.fb.group({
      comments: [this.data?.teamMember?.comments ?? '', []],
      first_name: [this.data?.teamMember?.first_name ?? '', [Validators.required]],
      last_name: [this.data?.teamMember?.last_name ?? '', [Validators.required]],
      degree: [this.data?.teamMember?.degree ?? '', []],
      organization: [this.data?.teamMember?.organization ?? '', []],
      speciality: [this.data?.teamMember?.speciality ?? '', []],
      type: [this.data?.teamMember?.type ?? '', [Validators.required]],
      phone: [this.data?.teamMember?.phone ? this.getNormalizedPhoneValue(this.data?.teamMember?.phone) : '', [phoneNumberValidator]],
      email: [this.data?.teamMember?.email ?? '', []],
      fax: [this.data?.teamMember?.fax ?? '', [phoneNumberValidator]],
      method: [this.data?.teamMember?.reports.method ?? '', []],
      rpm: [this.data?.teamMember?.reports.rpm ?? false, []],
      ccm: [this.data?.teamMember?.reports.ccm ?? false, []],
      activeBill: [this.data?.teamMember?.bill.active ?? false, []],
      ccmBill: [{ value: this.data?.teamMember?.bill.ccm ?? false, disabled: true }],
      rpmBill: [{ value: this.data?.teamMember?.bill.rpm ?? false, disabled: true }],
    });
    this.onToggleChange();
    this.teamMemberForm.get('activeBill')?.valueChanges.subscribe((value) => {
      this.onToggleChange();
    });
  }

  openNPIForm() {
    this.showNPIForm = true;
    this.teamMemberForm.disable();
  }

  searchNpi() {
    this.isLoading = true;
    this.npiError = null;
    this.newProviderService
      .getNpiData(this.npiFormGroup.value.npi)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          this.snackService.genericSnackBar(err, ['error-snackbar'], 5000);
          console.log(err);
          this.isLoading = false;
          return of(null);
        })
      )
      .subscribe((data) => {
        if (Object.keys(data).indexOf('Errors') !== -1) {
          this.npiError = data['Errors'][0].description;
          this.snackService.genericSnackBar(this.npiError);
        } else {
          this.teamMemberForm.patchValue({
            first_name: data.results[0]?.basic?.authorized_official_first_name ?? data.results[0]?.basic?.first_name,
            last_name: data.results[0]?.basic?.authorized_official_last_name ?? data.results[0]?.basic?.last_name,
            organization: data.results[0]?.basic?.organization_name ?? 'N/A',
            phone: this.getPhoneFromNPI(data),
            fax: data.results[0]?.addresses[0]?.fax_number ? data.results[0]?.addresses[0]?.fax_number : '',
          });
        }
        this.hideNPIForm();
        this.isLoading = false;
      });
  }

  onToggleChange() {
    if (this.teamMemberForm.get('activeBill').value) {
      this.teamMemberForm.get('ccmBill').enable();
      this.teamMemberForm.get('rpmBill').enable();
    } else {
      this.teamMemberForm.get('ccmBill').disable();
      this.teamMemberForm.get('rpmBill').disable();
      this.teamMemberForm.patchValue({ ccmBill: false, rpmBill: false });
    }
  }

  hideNPIForm() {
    this.showNPIForm = false;
    this.teamMemberForm.enable();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  private getNormalizedPhoneValue(phone: string) {
    const phoneWithoutSign = phone.substring(2).trim();
    return this.utilsService.getPhoneNumberFormated(phoneWithoutSign);
  }

  private getPhoneFromNPI(data) {
    if (data.results[0]?.basic?.authorized_official_telephone_number) {
      return data.results[0]?.basic?.authorized_official_telephone_number.includes('-')
        ? data.results[0]?.basic?.authorized_official_telephone_number
        : this.utilsService.getPhoneNumberFormated(data.results[0]?.basic?.authorized_official_telephone_number);
    } else {
      return data.results[0]?.addresses[0]?.telephone_number.includes('-')
        ? data.results[0]?.addresses[0]?.telephone_number
        : this.utilsService.getPhoneNumberFormated(data.results[0]?.addresses[0]?.telephone_number);
    }
  }
}
