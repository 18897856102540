import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { SecureEmail, SecureEmailForm } from 'src/app/models/secure-email.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { SecureEmailService } from 'src/app/services/communications/secure-email.service';
import { PatientCommunicationType } from 'src/app/services/models/patient-communication.model';
import { PatientCommunicationService } from 'src/app/services/patient-communication/patient-communication.service';
import { PatientService } from 'src/app/services/patient.service';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';
import { emailMultipleValidator } from '../../validators/email-multiple.validator';
import { emailWelbyValidator } from '../../validators/email-welby.validator';
import { SearchPatientContentDialogComponent } from '../search-patient-content-dialog/search-patient-content-dialog.component';

@Component({
  selector: 'app-email-patient-dialog',
  templateUrl: './email-patient-dialog.component.html',
  styleUrls: ['./email-patient-dialog.component.scss'],
})
export class EmailPatientDialogComponent implements OnInit, OnChanges {
  @ViewChild('inputFile') inputFile: ElementRef;
  @Input() email: string;
  @Input() isSurveyLinkAdded = false;
  @Input() surveyKey = '';
  @Input() subject = '';
  @Output() emailSent = new EventEmitter<boolean>();
  emailForm: FormGroup;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  selectable = true;
  removable = true;
  addOnBlur = true;
  isEmailSecure = false;
  isEmailSendViaPortal = true;
  loading = false;
  currentUser;
  welbyEmailRegex = /^[\w-\.]+@(getwelby+\.)+[\w-]{2,4}$/;
  attachments = [];
  shareContent = [];
  showBcc = false;
  surveyUrl = '';
  patientSurveys = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<EmailPatientDialogComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private secureEmailService: SecureEmailService,
    private snackService: SnackService,
    private authService: NewAuthService,
    private utilsService: UtilsService,
    private patientService: PatientService,
    private communicationService: PatientCommunicationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.surveyKey && this.emailForm) {
      this.emailForm.get('surveyOption').setValue(changes.surveyKey.currentValue);
    }
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.authService.user;
    if (this.email && this.data) {
      this.data.email = this.email;
    }
    this.initializeForm();
    this.patientSurveys = await this.utilsService.getSurveyForPatients(this.currentUser.client_responsible_id);
  }

  getValidWelbyEmail() {
    const email: string = this.currentUser?.email;
    return this.welbyEmailRegex.test(email) ? email : environment.defaultWelbyEmail;
  }

  initializeForm(): void {
    this.emailForm = this.fb.group({
      subject: [this.subject, Validators.required],
      recipients: [this.getInitialRecipients(), emailMultipleValidator],
      bcc: [[], emailMultipleValidator],
      cc: [[], emailMultipleValidator],
      from: [this.getValidWelbyEmail()],
      message: ['', Validators.required],
      isEmailSecure: [this.isEmailSecure, Validators.required],
      isSurveyLinkAdded: [this.isSurveyLinkAdded],
      forceSecureNotification: [this.isEmailSendViaPortal, Validators.required],
      surveyOption: [''],
    });
    this.emailForm.get('isSurveyLinkAdded').valueChanges.subscribe((value) => {
      if (value) {
        this.emailForm.get('surveyOption').setValidators([Validators.required]);
      } else {
        this.emailForm.get('surveyOption').clearValidators();
      }
      this.emailForm.get('surveyOption').updateValueAndValidity();
    });
    this.emailForm.get('surveyOption').valueChanges.subscribe((value) => {
      this.getSurveyOption(value);
    });
    this.emailForm.get('surveyOption').setValue(this.surveyKey ?? 'welby_survey');
  }

  async getSurveyOption(value: string): Promise<void> {
    const surveyConf = await this.utilsService
      .getSurveyUrl(value, this.patientService.currentPatientService.client_responsible_id, this.patientService.currentPatientService.user_id)
      .pipe(take(1))
      .toPromise();
    this.surveyUrl = surveyConf.shortUrl;
  }

  sendEmail(): void {
    if (this.emailForm.get(SecureEmailForm.IS_EMAIL_SECURE).value) {
      const emailInformation = this.transformFormIntoEmailbody();
      this.loading = true;
      this.sendSecureEmail(emailInformation);
    } else {
      this.sendNoSecureEmail();
    }
  }

  toggleBCC(): void {
    this.showBcc = !this.showBcc;
    if (this.showBcc) {
      this.emailForm.get(SecureEmailForm.BCC).setValidators([emailMultipleValidator]);
    } else {
      this.emailForm.get(SecureEmailForm.BCC).setValue([]);
      this.emailForm.get(SecureEmailForm.BCC).clearValidators();
    }
    this.emailForm.get(SecureEmailForm.BCC).updateValueAndValidity();
  }

  toggleSendSecure(): void {
    this.isEmailSecure = !this.isEmailSecure;
    if (this.isEmailSecure) {
      this.emailForm.get(SecureEmailForm.FROM).setValidators([Validators.required, emailWelbyValidator]);
    } else {
      this.emailForm.get(SecureEmailForm.FROM).clearValidators();
    }
    this.emailForm.get(SecureEmailForm.FROM).updateValueAndValidity();
    this.emailForm.get(SecureEmailForm.RECIPIENTS).setValue(this.getInitialRecipients());
  }

  toggleforceSecureNotification(): void {
    this.isEmailSendViaPortal = !this.isEmailSendViaPortal;
    this.emailForm.get(SecureEmailForm.FORCE_SECURE_NOTIFICATION).setValue(this.isEmailSendViaPortal);
    this.emailForm.get(SecureEmailForm.FORCE_SECURE_NOTIFICATION).updateValueAndValidity();
  }

  toggleAddSurveyLink(): void {
    this.isSurveyLinkAdded = !this.isSurveyLinkAdded;
    this.emailForm.get(SecureEmailForm.ISSURVEYLINKADDED).setValue(this.isSurveyLinkAdded);
    this.emailForm.get(SecureEmailForm.ISSURVEYLINKADDED).updateValueAndValidity();
  }

  close(): void {
    this.dialogRef.close();
  }

  add(event: MatChipInputEvent, formControlName: string): void {
    const value = (event.value || '').trim();
    if (value) {
      this.emailForm.get(formControlName).setValue([...this.emailForm.get(formControlName).value, value]);
    }
    if (event.input) {
      event.input.value = '';
    }
    this.emailForm.get(formControlName).updateValueAndValidity();
  }

  remove(email: string, formControlName: string): void {
    const index = this.emailForm.get(formControlName).value.indexOf(email);
    const emails: string[] = [...this.emailForm.get(formControlName).value];
    if (index >= 0) {
      emails.splice(index, 1);
      this.emailForm.get(formControlName).setValue(emails);
    }
  }

  openSelectFileDialog(event) {
    event.preventDefault();
    this.inputFile.nativeElement.click();
  }

  openSelectPatientContent() {
    const dialogRef = this.dialog.open(SearchPatientContentDialogComponent, {
      width: '600px',
      data: this.shareContent,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.length > 0) {
        this.shareContent = data;
      }
    });
  }

  deleteShareContent(): void {
    this.shareContent = [];
  }

  async onDocumentFileSelected(event) {
    event.preventDefault();
    const files = await Promise.all(
      Array.from(event.target.files).map(async (file: File) => {
        const fileBase64 = await this.utilsService.getBase64FromFile(file);
        return { fileName: file.name, contentType: file.type, content: fileBase64 };
      })
    );
    this.attachments = files ? [...files, ...this.attachments] : [];
  }

  private sendSecureEmail(emailInformation: SecureEmail): void {
    const communicationRecord = this.communicationService.buildCommunicationRecord(
      this.emailForm.get('subject').value,
      this.emailForm.get('message').value,
      this.patientService.currentPatientService,
      this.currentUser,
      PatientCommunicationType.EMAIL,
      this.data.fromDashboard ? emailInformation.recipients : [],
      this.attachments
    );
    this.secureEmailService
      .sendEmail(emailInformation)
      .pipe(
        mergeMap(() =>
          !this.data.fromDashboard ? this.communicationService.saveCommunicationRecord(communicationRecord, this.patientService?.currentPatientService?.user_id) : of([])
        )
      )
      .subscribe(
        () => {
          this.snackService.genericSnackBar('Email has been sent successfully', ['success-snackbar']);
          this.loading = false;
          this.emailSent.emit(true);
          this.dialogRef.close(true);
        },
        (error) => {
          this.snackService.genericSnackBar(`Internal error. Description: ${error.message}`, ['error-snackbar']);
          this.loading = false;
          this.emailSent.emit(false);
        }
      );
  }

  private async sendNoSecureEmail(): Promise<void> {
    const emails = (this.emailForm.get(SecureEmailForm.RECIPIENTS).value as [])?.join(';');
    const bcc = (this.emailForm.get(SecureEmailForm.BCC).value as [])?.join(';');
    const cc = (this.emailForm.get(SecureEmailForm.CC).value as [])?.join(';');
    const communicationRecord = this.communicationService.buildCommunicationRecord(
      `${this.emailForm.get('subject').value} (unsecure email sent)`,
      this.emailForm.get('message').value,
      this.patientService.currentPatientService,
      this.currentUser,
      PatientCommunicationType.EMAIL,
      this.data.fromDashboard ? this.emailForm.get(SecureEmailForm.RECIPIENTS).value : []
    );
    const link = `mailto:${emails}?bcc=${bcc}&cc=${cc}&subject=${this.emailForm.get('subject').value}&body=${this.addShareContentIntoNoSecureEmail()}`;
    const w = window.open('', '_blank', '');
    if (!this.data?.fromDashboard) {
      await this.communicationService.saveCommunicationRecord(communicationRecord, this.patientService?.currentPatientService?.user_id);
    }
    w.location.href = link;
    w.focus();
  }

  private getInitialRecipients(): string[] | string {
    const recipients = [];
    if (!this.welbyEmailRegex.test(this.currentUser.email)) {
      if (this.currentUser?.email && this.isEmailSecure) {
        recipients.push(this.currentUser.email);
      }
    }
    if (this.data?.email) {
      recipients.push(this.data.email);
    }
    return recipients.length > 0 ? recipients : [];
  }

  private transformFormIntoEmailbody(): SecureEmail {
    const emailInformation: SecureEmail = {
      recipients: this.emailForm.get(SecureEmailForm.RECIPIENTS).value,
      bcc: this.emailForm.get(SecureEmailForm.BCC).value,
      cc: this.emailForm.get(SecureEmailForm.CC).value,
      headers: {
        subject: this.emailForm.get(SecureEmailForm.SUBJECT).value,
        from: this.emailForm.get(SecureEmailForm.FROM).value,
      },
      content: {
        'text/html': this.shareContent.length > 0 ? this.addShareContentIntoEmail() : `<p>${this.emailForm.get(SecureEmailForm.MESSAGE).value}</p>`,
      },
      forceSecureNotification: this.emailForm.get(SecureEmailForm.FORCE_SECURE_NOTIFICATION).value,
      provider: { name: `${this.authService.user?.firstName} ${this.authService.user?.lastName}`, twilioLine: this.authService.user?.twilio_line },
    };
    if (this.attachments && this.attachments.length > 0) {
      emailInformation['attachments'] = this.attachments;
    }
    if (this.isSurveyLinkAdded) {
      emailInformation['content']['text/html'] = `${emailInformation['content']['text/html']}${this.addSurveyLinkIntoEmail()}`;
    }

    return emailInformation;
  }

  private addSurveyLinkIntoEmail() {
    return `<h4>We want to hear from you</h4><p>Please fill out the following survey: <a href=${this.surveyUrl}>Click Here</a></p>`;
  }
  private addSurveyLinkIntoEmailNoHtml() {
    return `%0D%0A%0D%0AWe want to hear from you.%0D%0A Please fill out the following survey: ${this.surveyUrl}%0D%0A`;
  }

  private addShareContentIntoEmail(): string {
    let result = `<div><p>${this.emailForm.get(SecureEmailForm.MESSAGE).value}</p>`;
    result = `${result} <h3><strong>Some useful links:</strong></h3>`;
    result = `${result} <ul>`;
    this.shareContent.forEach((content, index) => {
      result = `${result} <li><a href="${content.url}" target="_blank">${content.title}</a></li>`;
    });
    result = `${result} </ul></div>`;
    return result;
  }

  private addShareContentIntoNoSecureEmail(): string {
    let result = `${this.emailForm.get(SecureEmailForm.MESSAGE).value}`;
    if (this.shareContent?.length > 0) {
      result = `${result}%0D%0ASome useful links:%0D%0A`;
      this.shareContent.forEach((content, index) => {
        result = `${result}- (${content.title}) ${encodeURIComponent(content.url)}%0D%0A`;
      });
    }
    if (this.isSurveyLinkAdded) {
      result = `${result}${this.addSurveyLinkIntoEmailNoHtml()}`;
    }
    return result;
  }
}
