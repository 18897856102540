export interface PatientCommunicationRecord {
  type: PatientCommunicationType;
  icon?: string;
  note: PatientCommunicationNote;
  createdAt: Date | any;
  createdBy: { name: string; userId: string };
  patient?: { name: string; patientId: string };
  fromDashboard?: { recipients: string[] };
  attachments?: Attachments[];
}

export interface Attachments {
  fileName?: string;
  contentType?: string;
  content?: any;
}

export interface PatientCommunicationNote {
  message: string;
  subject?: string;
}

// eslint-disable-next-line no-shadow
export enum PatientCommunicationType {
  EMAIL = 'Email',
  SMS = 'SMS',
  CALL = 'Call',
  VIDEO_CONFERENCE = 'Video Conference',
}
