import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import * as moment from 'moment-timezone';
import { Observable, forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { VitalRecord } from 'src/app/models/vitals/vital-measure';
import { VitalFlagCategory } from 'src/app/patient/rpm/vital-flag-criteria/models/vital_flags.model';
import { DateTimeWelbyCustomPipe } from 'src/app/shared/pipes/date-time-custom.pipe';
import { environment } from 'src/environments/environment';
import { VitalMonthlySummaryResponse } from '../models/historical-vitals.model';
import { PatientService } from '../patient.service';

@Injectable({
  providedIn: 'root',
})
export class MeasureSummaryService {
  // days = {}; //this is just the object of all dats in the month
  returnCount = {};
  // this will populate with times like {'asdcvb': {rpms: 0, rpmc, 0, ccm: 0, other: 0}}
  returnTime = { user_id: '', rpmc: 0, rpms: 0, rpmTotal: 0, ccm: 0, other: 0, contacted: false, billable: 0, times: [] };
  currentMonth = this.addLeadingZero(new Date().getMonth() + 1);
  currentYear = new Date().getFullYear().toString();
  typesValues = {
    [VitalFlagCategory.BLOOD_PRESSURE]: ['sbp', 'dbp'],
    [VitalFlagCategory.BLOOD_GLUCOSE]: ['mgdl'],
    [VitalFlagCategory.PULSE_OX]: ['spo2', 'pulse_bpm'],
    [VitalFlagCategory.WEIGHT]: ['lbs'],
    [VitalFlagCategory.TEMPERATURE]: ['temp'],
    [VitalFlagCategory.PULSEBPM]: ['pulse_bpm'],
  };

  averageMeasures$: Observable<VitalRecord[]>;
  // this is just a small function to summarize the number of measures per month.
  constructor(private db: AngularFirestore, private ps: PatientService, private datePipe: DateTimeWelbyCustomPipe, private http: HttpClient) {}

  relevantVitals(uid: string) {
    return this.http
      .get<VitalMonthlySummaryResponse>(`${environment.welbyEndpoint}/api/v1/core/patients/${uid}/vitals/monthly-summary`)
      .pipe(
        take(1),
        map((response) => ({ days: response.uniqueDays, total: response.total }))
      )
      .toPromise();
  }

  getWelbyConf(): Observable<any> {
    const welbyRpmConf = this.db.collection('clinical_time_configuration').doc('rpm_threshold').valueChanges().pipe(take(1));
    const welbyCcmConf = this.db.collection('clinical_time_configuration').doc('ccm_threshold').valueChanges().pipe(take(1));
    const welbyBhiConf = this.db.collection('clinical_time_configuration').doc('bhi_threshold').valueChanges().pipe(take(1));
    const welbyPcmConf = this.db.collection('clinical_time_configuration').doc('pcm_threshold').valueChanges().pipe(take(1));
    return forkJoin([welbyRpmConf, welbyCcmConf, welbyBhiConf, welbyPcmConf]).pipe(map((resp) => ({ rpmConf: resp[0], ccmConf: resp[1], bhiConf: resp[2], pcmConf: resp[3] })));
  }

  getDaysOfMonthInMap(): { [key: string]: number } {
    const date = moment.tz(`${this.currentYear}-${this.currentMonth}-01T00:00:00`, this.ps?.currentPatientService?.timezone?.nameValue);
    const localDays = {};
    while (date.format('MM') === this.currentMonth) {
      localDays[date.format('yyyy-MM-DD')] = 0;
      date.add(1, 'day');
    }
    return localDays;
  }

  async summarizeTime(uid: string) {
    return this.http
      .get(`${environment.welbyEndpoint}/api/v1/core/patients/${uid}/clinical-time/monthly-summary`)
      .pipe(
        take(1),
        map((response: { status: string; data: any }) => response.data)
      )
      .toPromise();
  }

  async getAverageMeasures(uid: string, p1: number, p2: number): Promise<any> {
    const now = new Date();
    const d1 = new Date(new Date().setDate(now.getDate() - p1));
    const d2 = new Date(new Date().setDate(now.getDate() - p2));

    const startDate = p2 > p1 ? d2 : d1; // determining the longer window to start the lookup
    const vitals = [];

    const averages = {}; // what will get returned as an object

    await this.db
      .collection('vitals')
      .ref.where('measureDate', '>=', firebase.firestore.Timestamp.fromDate(startDate))
      .where('invalid', '==', false)
      .where('user_id', '==', uid)
      .orderBy('measureDate', 'desc')
      .get()
      .then((snap) => {
        if (snap.empty) {
          averages['none'] = { type: 'none', units: 'N/A', a1: null, a2: null, lastMeasure: 0, priorMeasure: 0 }; // return empty
        } else {
          const types: string[] = [];
          snap.docs.forEach((v) => {
            vitals.push(v.data());

            if (types.indexOf(v.data().measure_type) === -1) {
              types.push(v.data().measure_type);
              // averages[v.data().measure_type] = {type: v.data().measure_type, units: v.data().units, a1: 0, a2: 0, lastMeasure: 0, priorMeasure: 0}
            }
          });

          // then per type, get the average for each
          types.forEach((t) => {
            const array1 = vitals.filter((v) => v.measureDate.toDate() > d1 && v.measure_type === t);
            const array2 = vitals.filter((v) => v.measureDate.toDate() > d2 && v.measure_type === t);

            // sums
            let s1 = {};
            let s2 = {};
            // lengths
            const l1 = array1.length;
            if (l1 > 0) {
              array1.forEach((vital) => {
                s1 = this.buildAverageObject(t, vital, s1);
              });
            } else {
              s1 = this.buildAverageObject(t, null, s1);
            }
            const l2 = array2.length;
            if (l2 > 0) {
              array2.forEach((vital) => {
                s2 = this.buildAverageObject(t, vital, s2);
              });
            } else {
              s2 = this.buildAverageObject(t, null, s2);
            }
            // set the value for each record in the objec by type
            averages[t] = {
              type: t,
              units: array2[0].units,
              a1:
                l1 > 0
                  ? {
                      value: this.toObjectFromArray(Object.keys(s1).map((key) => ({ [key]: s1[key] / l1 }))),
                      units: array2[0].units || array1[0].units,
                    }
                  : null,
              a2:
                l2 > 0
                  ? {
                      value: this.toObjectFromArray(Object.keys(s2).map((key) => ({ [key]: s2[key] / l2 }))),
                      units: array2[0].units || array1[0].units,
                    }
                  : null,
              lastMeasure: {
                ...array2[0],
                measureDate: this.datePipe.transform(array2[0].measureDate?.toDate(), this.ps?.currentPatientService?.timezone?.nameValue),
              },
              priorMeasure: array2[1],
            };
          });
        }
      });
    return averages;
  }

  addLeadingZero(n: number) {
    return n < 10 ? '0' + n : '' + n;
  }

  /**
   * build an avg object with prop keys according to vital measurements
   */
  buildAverageObject(type: string, currentValue: VitalRecord, objectToBuild) {
    const [firstType, secondType] = this.typesValues[type] || [];

    const getValueOrDefault = (key) => Number(currentValue?.value[key]) || 0;
    const addToObject = (object, key: string, value) => ({ ...object, [key]: value });

    let avgObject = {};

    if (this.typesValues[type]?.length > 1) {
      // When the type has more than one value
      if (objectToBuild[firstType] && objectToBuild[secondType]) {
        avgObject = {
          [firstType]: objectToBuild[firstType] + getValueOrDefault(firstType),
          [secondType]: objectToBuild[secondType] + getValueOrDefault(secondType),
        };
      } else {
        avgObject = {
          [firstType]: getValueOrDefault(firstType),
          [secondType]: getValueOrDefault(secondType),
        };
      }
    } else {
      if (objectToBuild[firstType]) {
        avgObject = addToObject(avgObject, firstType, objectToBuild[firstType] + getValueOrDefault(firstType));
      } else {
        if (this.typesValues[type]) {
          avgObject = addToObject(avgObject, firstType, getValueOrDefault(firstType));
        }
      }
    }
    return avgObject;
  }

  toObjectFromArray(array, initialState = {}) {
    return array.reduce((result, item) => {
      const key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    }, initialState);
  }
}
