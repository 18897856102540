<mat-card>
  <mat-card-header>
    <mat-card-title>Task</mat-card-title>
    <mat-chip-list class="small-mat-chip" *ngIf="taskForm?.get('priority')?.value">
      <ng-container [ngSwitch]="taskForm.get('priority').value">
        <mat-chip *ngSwitchCase="'high'" color="warn" selected>High</mat-chip>
        <mat-chip *ngSwitchCase="'medium'" color="accent" selected>Medium</mat-chip>
        <mat-chip *ngSwitchCase="'low'" color="default" selected>Low</mat-chip>
        <mat-chip *ngSwitchDefault color="default">Low</mat-chip>
      </ng-container>
    </mat-chip-list>
  </mat-card-header>
  <mat-card-content>
    <form class="task-form" [formGroup]="taskForm" (ngSubmit)="saveTask()">
      <div class="task-form-row">
        <app-search-patient-autocomplete class="task-form-field" [searchModel]="searchModel" [searchInput]="taskForm.get('patient')"></app-search-patient-autocomplete>
      </div>
      <mat-form-field class="patient-list-filter-select">
        <mat-label>Task owner</mat-label>
        <mat-select formControlName="task_owner">
          <mat-option *ngFor="let provider of providers" [value]="provider.user_id"> {{ provider.firstName }} {{ provider.lastName }} </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Follow-up date</mat-label>
        <input matInput formControlName="due_date" [matDatepicker]="picker" placeholder="Follow-up date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Task</mat-label>
        <textarea matInput formControlName="task_name" placeholder="Task name" rows="5"></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" placeholder="Task description" rows="5"></textarea>
      </mat-form-field>
      <mat-slide-toggle color="primary" labelPosition="before" formControlName="completed">Is completed? </mat-slide-toggle>
      <div fxFlex fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
        <button mat-button type="button" (click)="cancel()">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="taskForm.invalid" type="submit" cdkFocusInitial>Save</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
