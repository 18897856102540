import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import * as firebase from 'firebase';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AlertContact, Patient, PatientAddress, PatientContact } from 'src/app/models/patient.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { DataService } from 'src/app/services/data.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PatientDetailService } from 'src/app/services/patient-detail.service';
import { PatientService } from 'src/app/services/patient.service';
import { SnackService } from 'src/app/services/snack.service';
import { AreYouSureModalComponent } from 'src/app/shared/are-you-sure-modal/are-you-sure-modal.component';

@Component({
  selector: 'app-patient-demo-home',
  templateUrl: './patient-demo-home.component.html',
  styleUrls: ['./patient-demo-home.component.scss'],
})
export class PatientDemoHomeComponent implements OnInit, OnDestroy {
  currentPatient: Patient;
  patient_id: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  isOpenCreateAddressForm = false;
  isOpenCreateContactForm = false;
  isOpenCreateAlertForm = false;
  subscription = new Subscription();
  loading = false;
  unsubscribe = new Subject();

  constructor(
    private data: DataService,
    private fsService: FirestoreService,
    private db: AngularFirestore,
    private snackBar: SnackService,
    private patientService: PatientService,
    private patientDetailService: PatientDetailService,
    private auth: NewAuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.patientService.currentPatient$
      .pipe(
        takeUntil(this.unsubscribe),
        filter((patient) => patient !== null),
        tap((patient) => (this.currentPatient = patient)),
        tap((patient) => (this.patient_id = patient.user_id)),
        switchMap(() => combineLatest([this.patientDetailService.addresses$, this.patientDetailService.contacts$]))
      )
      .subscribe(([addresses, contacts]) => {
        this.currentPatient.addresses = addresses;
        this.currentPatient.contacts = contacts;
      });
  }

  saveAddress(event: PatientAddress): void {
    if (event.id) {
      this.fsService
        .update(`users/${this.patient_id}/my_addresses/${event.id}`, event)
        .then(() => {
          this.openSuccessSnackBar('Address updated!');
        })
        .catch((error) => {
          this.openErrorSnackBar(error);
          console.log('error', error);
        });
    } else {
      delete event.id;
      this.fsService
        .add(`users/${this.patient_id}/my_addresses`, event)
        .then(() => {
          this.openSuccessSnackBar('Address created!');
          this.isOpenCreateAddressForm = false;
        })
        .catch((error) => {
          console.log('error', error);
          this.openErrorSnackBar(error);
        });
    }
  }
  async saveContact(event: PatientContact): Promise<void> {
    await this.updateAllContactsWithPrimaryFalse(event.isPrimary, event.isPrimaryForSms);
    if (event.isPrimaryForSms) {
      await this.fsService.update(`users/${this.patient_id}`, { sms_contact: event.contact_data });
    }
    if (event.id) {
      this.fsService
        .upsert(`users/${this.patient_id}/my_contacts/${event.id}`, event)
        .then(() => this.openSuccessSnackBar('Contact updated!'))
        .catch((error) => {
          console.log('error', error);
          this.openErrorSnackBar(error);
        });
    } else {
      delete event.id;
      this.fsService
        .add(`users/${this.patient_id}/my_contacts`, event)
        .then(() => {
          this.isOpenCreateContactForm = false;
          this.openSuccessSnackBar('Contact created!');
        })
        .catch((error) => {
          console.log('error', error);
          this.openErrorSnackBar(error);
        });
    }
  }

  savePatient(event: Patient): void {
    if (event.user_id) {
      this.loading = true;
      event.search_terms = this.generateSearchTerms(event);
      this.fsService
        .upsert(`users/${this.patient_id}`, event)
        .then(async (response) => {
          const updatedPatient = await this.patientService.getPatient(this.patient_id);
          await this.patientService.setCurrentPatient(updatedPatient);
          this.openSuccessSnackBar('Basic patient information updated!');
          this.loading = false;
        })
        .catch((error) => {
          console.log('error', error);
          this.openErrorSnackBar(error);
          this.loading = false;
        });
    }
  }

  saveAlert(event): void {
    const updatedContact: AlertContact = {
      data: event.alert.data,
      type: event.alert.type,
    };
    if (!event.prevAlert) {
      this.db
        .collection('users')
        .doc(this.patient_id)
        .update({ alert_contacts: firebase.firestore.FieldValue.arrayUnion(updatedContact) })
        .then((resp) => {
          this.openSuccessSnackBar('Alert contact added!');
          this.currentPatient.alert_contacts.push(updatedContact);
          this.isOpenCreateAlertForm = false;
        });
    } else {
      this.db
        .collection('users')
        .doc(this.patient_id)
        .update({ alert_contacts: firebase.firestore.FieldValue.arrayRemove(event.prevAlert) })
        .then(() => {
          this.db
            .collection('users')
            .doc(this.patient_id)
            .update({ alert_contacts: firebase.firestore.FieldValue.arrayUnion(updatedContact) })
            .then(() => {
              this.openSuccessSnackBar('Alert contact updated!');
              this.isOpenCreateAlertForm = false;
            });
        });
    }
  }

  deleteAddress(address: PatientAddress): void {
    const uid = this.patient_id;
    const dialog = this.dialog.open(AreYouSureModalComponent, {
      width: '500px',
      data: {
        title: 'Delete address',
        body: `Are you sure you want to delete '${address.address} ...'?`,
      },
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.db
          .collection('users')
          .doc(uid)
          .collection('my_addresses')
          .doc(address.id)
          .delete()
          .then((resp) => {
            this.openSuccessSnackBar('Address deleted!');
          })
          .catch((error) => {
            this.openErrorSnackBar(error);
          });
      }
    });
  }

  deleteContact(contact: PatientContact): void {
    const uid = this.patient_id;
    const dialog = this.dialog.open(AreYouSureModalComponent, {
      width: '500px',
      data: {
        title: 'Delete contact',
        body: `Are you sure you want to delete '${contact.contact_data}' contact number?`,
      },
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.db
          .collection('users')
          .doc(uid)
          .collection('my_contacts')
          .doc(contact.id)
          .delete()
          .then(() => {
            this.openSuccessSnackBar('Contact number deleted!');
          })
          .catch((error) => {
            this.openErrorSnackBar(error);
          });
      }
    });
  }

  deleteAlertContact(contact: AlertContact) {
    const uid = this.patient_id;
    this.db
      .collection('users')
      .doc(uid)
      .update({ alert_contacts: firebase.firestore.FieldValue.arrayRemove(contact) })
      .then((resp) => {
        const index = this.currentPatient.alert_contacts.findIndex((alert) => alert.data === contact.data);
        this.currentPatient.alert_contacts.splice(index, 1);
        this.openSuccessSnackBar('Alert contact deleted!');
      });
  }

  openSuccessSnackBar(message: string): void {
    this.snackBar.genericSnackBar(message, ['success-snackbar'], 3000);
  }

  openErrorSnackBar(message: string): void {
    this.snackBar.genericSnackBar(message, ['error-snackbar'], 3000);
  }

  generateSearchTerms(patient: Patient): string[] {
    const terms = [
      patient.firstName.toLowerCase(),
      patient.lastName.toLowerCase(),
      patient.email.toLowerCase(),
      this.data.getFirstName(patient.provider_id).toLowerCase(),
      this.data.getLastName(patient.provider_id).toLowerCase(),
    ];

    if (this.currentPatient.user_tags && this.currentPatient.user_tags.length > 0) {
      for (const t of this.currentPatient.user_tags) {
        terms.push(t.name);
      }
    }

    return terms;
  }

  async changeLoginEmail(event: { email: string; patientId: string }): Promise<void> {
    try {
      await this.auth.changeEmail(event.email, event.patientId);
      this.snackBar.genericSnackBar('Email changed successfully!', ['success-snackbar'], 5000);
    } catch (error) {
      this.snackBar.genericSnackBar('Error changing email!', ['error-snackbar'], 5000);
      console.error(`Error changing email: ${error}`);
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  private updateAllContactsWithPrimaryFalse(isPrimary?: boolean, isPrimaryForSms?: boolean): Promise<void[]> {
    const result = Promise.all(
      this.currentPatient?.contacts.map(async (contact) =>
        this.fsService.update(`users/${this.patient_id}/my_contacts/${contact.id}`, { ...(isPrimary && { isPrimary: false }), ...(isPrimaryForSms && { isPrimaryForSms: false }) })
      )
    );
    return result;
  }
}
