import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';
import { Medication } from 'src/app/models/fhir-models';
import { PatientService } from 'src/app/services/patient.service';
import { SnackService } from 'src/app/services/snack.service';
import { NihAutoComplete } from '../../fhir-medications.model';
import { FhirMedicationsService } from '../../fhir-medications.service';

@Component({
  selector: 'app-fhir-medication-detail',
  templateUrl: './fhir-medication-detail.component.html',
  styleUrls: ['./fhir-medication-detail.component.scss'],
})
export class FhirMedicationDetailComponent implements OnInit {
  medicationForm: FormGroup;
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  hours=['AM','PM','As needed'];
  doseTypes = ['Oral Tablet', 'Oral Capsule', 'Nasal Solution', 'Other'];
  doses = ['mG', 'MCG', '%', 'Other'];
  frequencyOptions=[];
  searching = false;
  loading = false;
  title: string;

  constructor(
    private dialogRef: MatDialogRef<FhirMedicationDetailComponent>,
    private fb: FormBuilder,
    private fhirService: FhirMedicationsService,
    private patientService: PatientService,
    @Inject(MAT_DIALOG_DATA) public data,
    private snackBarService: SnackService
  ) {}

  async ngOnInit(): Promise<void> {
    this.frequencyOptions = await this.fhirService.getOptionsFrequencyMedications();
    this.title = this.data ? 'Edit Medication' : 'Add Medication';
    this.initializeForm(); 
    this.getDisplayNames();
    this.setFilter();
  }
 


  setFilter() {
    this.filteredOptions = this.medicationForm.get('name').valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      filter((value) => value && value.length > 2),
      tap(() => (this.loading = true)),
      debounceTime(200),
      map((value) => this.filter(value)),
      tap(() => (this.loading = false))
    );
  }

  public filter(name: string): string[] {
    const filterValue = name.toLowerCase();
    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }

  async addMedication() {
    try {
      this.loading = true;
      const medication: Medication = {
        dateWritten: new Date(),
        dosage: `${this.medicationForm.get('dosageAmount').value} ${this.medicationForm.get('dosageMeasure').value} ${this.medicationForm.get('drugForm').value}`,
        id: this.medicationForm.get('id').value,
        medref: 'N/A',
        name: this.medicationForm.get('name').value,
        prescriber: 'TBD',
        dosageAmount: this.medicationForm.get('dosageAmount').value,
        dosageMeasure: this.medicationForm.get('dosageMeasure').value,
        form: this.medicationForm.get('drugForm').value,
        frequency: this.medicationForm.get('frequency').value === 'Other' ? this.medicationForm.get('otherFrequency').value : this.medicationForm.get('frequency').value,
        hours: this.medicationForm.get('selectedHours').value 
      };
      await this.fhirService.addMedicationToPatient(this.patientService.currentPatientServiceID, medication);
      this.clearDx();
      this.snackBarService.genericSnackBar('Medication saved', ['success-snackbar']);
      this.close();
    } catch (error) {
      console.error(error);
      this.snackBarService.genericSnackBar('Error saving medication', ['error-snackbar']);
    } finally {
      this.loading = false;
    }
  }

  clearDx() {
    this.medicationForm.reset();
    this.medicationForm.controls['name'].setErrors(null);
    this.medicationForm.controls['dosageAmount'].setErrors(null);
    this.medicationForm.controls['dosageMeasure'].setErrors(null);
    this.medicationForm.controls['drugForm'].setErrors(null);
  }

  close(): void {
    this.dialogRef.close();
  }

  private getDisplayNames() {
    this.fhirService.getDisplayTerms().subscribe((data: NihAutoComplete) => (this.options = data.displayTermsList.term));
  }

  private initializeForm(): void {
    this.medicationForm = this.fb.group({
      id: [this.data?.id ?? ''],
      name: [this.data?.name ?? '', Validators.required],
      dosage: [this.data?.dosage ?? ''],
      dosageAmount: [this.data?.dosageAmount ?? '', Validators.required],
      dosageMeasure: [this.data?.dosageMeasure ?? '', Validators.required],
      drugForm: [this.data?.form ?? ''],
      frequency: [
        this.data?.frequency
          ? this.isValidFrequency(this.data?.frequency)
            ? this.data?.frequency
            : 'Other'
          : '', 
        Validators.required
      ],
      otherFrequency: [
        this.data?.frequency && !this.isValidFrequency(this.data?.frequency)
          ? this.data?.frequency
          : '' 
      ],
      selectedHours:[this.data?.hours ?? '']
    });
  }

  isValidFrequency(frequency: string | undefined): boolean {
    return this.frequencyOptions.some(option => option.value === frequency);
  }

}
